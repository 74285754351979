import { SHOW_MAX, SLIDER_TYPE } from 'Constants/listingSlider';
import { createSelector } from 'reselect';
import { itemsFacetMetadataSelector } from './items';
import { getFiltersArr } from './filtersTanak';
import * as urlHelper from 'Helpers/url';
import { INSPECTED_FILTER_ATTR, OLX_AUTOS_FILTER_ATTR } from 'Constants/filters';
import { getListingSliderType } from 'Helpers/listingSlider';

const maxSlidesForViewAllCTA = 4;

export const inspectionSliderData = createSelector(
    ({ listingSlider }) => listingSlider && listingSlider.elements,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.INSPECTION].collections,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.INSPECTION].lastCollectionUrl,
    (elements, collections, url) => {
        const sliderData = (collections[url] || []).map(id => elements[id]);

        if (sliderData.length > maxSlidesForViewAllCTA) {
            sliderData.splice(-1, 1);
        }

        return sliderData;
    }
);

export const showCTAInspectionSlider = createSelector(
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.INSPECTION].collections,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.INSPECTION].lastCollectionUrl,
    (collections, url) => {
        return (collections[url] || []).length > maxSlidesForViewAllCTA;
    }
);

export const showInspectionSlider = createSelector(
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.INSPECTION] && listingSlider[SLIDER_TYPE.INSPECTION].collections,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.INSPECTION] && listingSlider[SLIDER_TYPE.INSPECTION].lastCollectionUrl,
    itemsFacetMetadataSelector,
    getFiltersArr,
    (collections, url, itemsMetadata, filtersForCategory) => {
        if (collections && url) {
            const appliedFilters = (itemsMetadata && itemsMetadata.applied_filters) || [];
            const isInspectedCarFilterSelected = appliedFilters.some(({ id }) => id === INSPECTED_FILTER_ATTR);
            const urlObject = urlHelper.buildObjectFromURL(url);
            const isInspectedCarFilterInURL = urlObject && urlObject.params && urlObject.params[INSPECTED_FILTER_ATTR];
            const isInspectedCarFilterInConfig = filtersForCategory.includes(INSPECTED_FILTER_ATTR);

            return (
                isInspectedCarFilterInConfig
                && !isInspectedCarFilterSelected
                && !isInspectedCarFilterInURL
                && collections[url]
                && collections[url].length > SHOW_MAX.INSPECTION
            );
        }
        return false;
    }
);

export const olxAutosSliderData = createSelector(
    ({ listingSlider }) => listingSlider && listingSlider.elements,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.OLX_AUTOS].collections,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.OLX_AUTOS].lastCollectionUrl,
    (elements, collections, url) => {
        const sliderData = (collections[url] || []).map(id => elements[id]);

        if (sliderData.length > maxSlidesForViewAllCTA) {
            sliderData.splice(-1, 1);
        }

        return sliderData;
    }
);

export const showCTAOlxAutosSlider = createSelector(
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.OLX_AUTOS].collections,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.OLX_AUTOS].lastCollectionUrl,
    (collections, url) => {
        return (collections[url] || []).length > maxSlidesForViewAllCTA;
    }
);

export const showOlxAutosSlider = createSelector(
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.OLX_AUTOS] && listingSlider[SLIDER_TYPE.OLX_AUTOS].collections,
    ({ listingSlider }) => listingSlider && listingSlider[SLIDER_TYPE.OLX_AUTOS] && listingSlider[SLIDER_TYPE.OLX_AUTOS].lastCollectionUrl,
    itemsFacetMetadataSelector,
    getFiltersArr,
    (collections, url, itemsMetadata, filtersForCategory) => {
        if (collections && url) {
            const appliedFilters = (itemsMetadata && itemsMetadata.applied_filters) || [];
            const isOlxAutosCarFilterSelected = appliedFilters.some(({ id }) => id === OLX_AUTOS_FILTER_ATTR);
            const isOlxAutosCarFilterInConfig = filtersForCategory.includes(OLX_AUTOS_FILTER_ATTR);

            return (
                isOlxAutosCarFilterInConfig
                && !isOlxAutosCarFilterSelected
                && collections[url]
                && collections[url].length > SHOW_MAX.OLX_AUTOS
            );
        }
        return false;
    }
);

export const listingSliderData = createSelector(
    (state, sliderType) => Object.assign({}, { state, sliderType }),
    ({ state, sliderType }) => {
        switch (sliderType) {
            case SLIDER_TYPE.OLX_AUTOS:
                return olxAutosSliderData(state);

            case SLIDER_TYPE.INSPECTION:
                return inspectionSliderData(state);

            default:
                return [];
        }
    }
);

export const listingSliderTypeSelector = createSelector(
    (state, { marketConfig, category }) => Object.assign({}, { state, marketConfig, category }),
    ({ state, marketConfig, category }) => {
        const sliderType = getListingSliderType(marketConfig, category);

        switch (sliderType) {
            case SLIDER_TYPE.OLX_AUTOS:
                return showOlxAutosSlider(state, category.id) && sliderType;

            case SLIDER_TYPE.INSPECTION:
                return showInspectionSlider(state, category.id) && sliderType;

            default:
                return false;
        }
    }
);

export const showCTAOnSlider = createSelector(
    (state, sliderType) => Object.assign({}, { state, sliderType }),
    ({ state, sliderType }) => {
        switch (sliderType) {
            case SLIDER_TYPE.OLX_AUTOS:
                return showCTAOlxAutosSlider(state);

            case SLIDER_TYPE.INSPECTION:
                return showCTAInspectionSlider(state);

            default:
                return false;
        }
    }
);
