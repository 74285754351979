/* global window */

export const offlineHandler = thisComponent => {
    const offlineTimeout = 5000;

    thisComponent.timer = setTimeout(() => {
        thisComponent.setState({ offline: true });
        document.body.style.pointerEvents = 'none';
    }, offlineTimeout);
};

export const onlineHandler = thisComponent => {
    document.body.style.pointerEvents = 'auto';
    clearTimeout(thisComponent.timer);
    thisComponent.setState({
        offline: false,
        showOnlineMessage: true
    });
};

export const addOfflineEventListeners = thisComponent => {
    if (typeof window !== 'undefined') {
        window.addEventListener('online', () => onlineHandler(thisComponent), false);
        window.addEventListener('offline', () => offlineHandler(thisComponent), false);
    }
};

export const removeOfflineEventListeners = thisComponent => {
    if (typeof window !== 'undefined') {
        window.removeEventListener('online', () => onlineHandler(thisComponent), false);
        window.removeEventListener('offline', () => offlineHandler(thisComponent), false);
    }
};
