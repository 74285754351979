import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { sortingOptionsSelector, currentSortingSelector } from 'Selectors/sorting';
import { getCurrentSorting, getSortingOptions } from 'Selectors/filtersTanak';
import { DEFAULT_CATEGORY } from 'Constants/categories';

const withSorting = WrappedComponent => {
    class WithSorting extends React.PureComponent {
        render() {
            const { sortingOptionsTanak, currentSortingTanak } = this.props;

            return (
                <WrappedComponent
                    { ...this.props }
                    sortingOptions={ sortingOptionsTanak }
                    defaultSorting={ currentSortingTanak }
                    currentSorting={ currentSortingTanak }
                    data-aut-id="withSortingComponent"
                />
            );
        }
    }

    WithSorting.propTypes = {
        getSortingOptions: PropTypes.shape({
            values: PropTypes.array,
            def_value: PropTypes.string
        }),
        currentSorting: PropTypes.string,
        marketConfig: PropTypes.object,
        sortingOptionsTanak: PropTypes.array,
        currentSortingTanak: PropTypes.string,
        experiments: PropTypes.object
    };

    WithSorting.fetchData = (...args) => {
        const promises = [(WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())];

        return Promise.all(promises);
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithSorting.displayName = `withSorting(${wrappedComponentName})`;

    const mapStateToProps = (state, props) => {
        const categoryID = props.categoryID || DEFAULT_CATEGORY;

        return {
            getSortingOptions: sortingOptionsSelector(state, props),
            currentSorting: currentSortingSelector(state, props),
            sortingOptionsTanak: getSortingOptions(state, categoryID),
            currentSortingTanak: getCurrentSorting(state, categoryID)
        };
    };

    return hoistNonReactStatic(
        connect(mapStateToProps, null)(WithSorting),
        WrappedComponent,
        { fetchData: true }
    );
};

export default withSorting;
