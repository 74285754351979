export const UNAVAILABLE_BOOST_YEAR = 'unavailable_boosted_year';

export const getNewSearchResultsTitle = (itemsMetadata, showUnavailableYearTitle, intl) => {
    if (!showUnavailableYearTitle) {
        return '';
    }

    const {
        applicable_suggestions = [],
        unavailable_boosted_year: unavailable_boost_year,
        total,
        total_suggested_ads
    } = itemsMetadata;

    let title = '';

    if (applicable_suggestions.includes(UNAVAILABLE_BOOST_YEAR)) {
        const translationId = 'listingResultsTitleUnavailableYear';
        const count = total || total_suggested_ads || 0;

        const values = {
            unavailable_boost_year,
            count
        };

        title = intl?.formatMessage?.({ id: translationId }, values) || translationId;
    }

    return title;
};
