import React from 'react';
import { FormattedMessage as Translation } from 'react-intl';
import { getFeaturedIntegrationTag } from 'Helpers/item';
import { FEATURED_PTT_TAG, PTT_TAG } from '../components/constants';

export const renderIntegrationFeatureTag = (tag = {}, css) => (
    <span
        className={ css }
        style={ { background: tag.background_color, color: tag.text_color } }
    >
        <span>{tag.tag}</span>
    </span>
);

export const renderFeaturedTag = css => (
    <span className={ css }>
        <Translation id="listing_ad_featured_label">{ val => val }</Translation>
    </span>
);

export const renderPTT = (css, staticAssetUrl, ptt) => {
    return (<span className={ css }>
        <img src={ `${staticAssetUrl}${ptt.IMAGE}` } alt={ ptt.ALT } />
        <Translation id="listing_ad_featured_label">{ val => val }</Translation>
    </span>);
};

export const getStatus = ({ showFeaturedTag, isFeatured, isSponsored, tags, css, staticAssetUrl }) => {
    const integrationFeatureTag = getFeaturedIntegrationTag(tags);

    // Integration Feature Tag will always have the priority over normal feature tag
    if (integrationFeatureTag) {
        return renderIntegrationFeatureTag(integrationFeatureTag, css);
    }
    else if (showFeaturedTag && isFeatured && isSponsored) {
        return renderPTT(css, staticAssetUrl, FEATURED_PTT_TAG);
    }
    else if (showFeaturedTag && !isFeatured && isSponsored) {
        return renderPTT(css, staticAssetUrl, PTT_TAG);
    }
    else if (showFeaturedTag && isFeatured) {
        return renderFeaturedTag(css);
    }
    return null;
};

