/* global window */

import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { CAR_COMPARE_ICON, CAR_COMPARE_ICON_FILLED } from 'Constants/imageUrls';
import withConfig from 'HOCs/withConfig/withConfig';
import store from '../../../../app/store';
import PropTypes from 'prop-types';
import css from './CompareIconCTA.APP_TARGET.scss';
import useItemInfo from 'Hooks/useItemInfo';
import setToastMessageState from 'Actions/setToastMessageState';
import { FormattedMessage as Translation } from 'react-intl';
import { getItem as getSessionStorageItem, setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { CAR_COMPARE_EVENTS, CAR_COMPARE_FLOW_STEP } from 'Constants/tracking';
import withTrack from 'HOCs/withTrack/withTrack';
import { itemSelector } from 'Selectors/compare';
import withRouter from 'HOCs/withRouter';
import { getLocalStorageItem, setLocalStorageItem } from 'Helpers/localStorage';
import { CAR_COMPARISION, COMPARE_PAGE_OPENED } from 'Constants/comparePage';

export const CompareIconCTA = ({
    config,
    router,
    track,
    compareCars,
    itemId,
    showInHeader,
    isListingPage,
    isFirstItemCard,
    className,
    openComparePopup,
    item,
    setToastMessageState,
    isMyZone,
    flowStep,
    categories
}) => {
    const maxCarsToCompare = 3;
    const maxNudgesShowCount = 3;
    const headerTooltipTime = 2000;
    const itemCardTooltipTime = 3000;
    const staticAsseturl = config.get('staticAssets');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { displayTitle: title, price, image } = isMyZone ? item : useItemInfo(item, categories);

    const compareCarsCount = Object.keys(compareCars).length;

    const [showHeaderTooltip, setShowHeaderTooltip] = useState(false);
    const [showItemCardTooltip, setShowItemCardTooltip] = useState(false);

    const showItemCardTooltipNudges = () => {
        const comparePageOpened = JSON.parse(getLocalStorageItem(COMPARE_PAGE_OPENED));
        const showItemCardTooltipInSession = JSON.parse(getSessionStorageItem('show-item-card-tooltip'));
        const itemCardTooltipShowTotalCount = JSON.parse(getLocalStorageItem('item-card-tooltip-show-count'));

        const showItemCardTooltip = isFirstItemCard && !comparePageOpened && !showItemCardTooltipInSession
                && (itemCardTooltipShowTotalCount < maxNudgesShowCount);

        if (showItemCardTooltip) {
            setSessionStorageItem('show-item-card-tooltip', true);
            setLocalStorageItem('item-card-tooltip-show-count', itemCardTooltipShowTotalCount + 1);

            setTimeout(() => {
                setShowItemCardTooltip(false);
            }, itemCardTooltipTime);

            setShowItemCardTooltip(true);
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        showItemCardTooltipNudges();

        if (showInHeader || isFirstItemCard) {
            const handleScroll = () => {
                if (showInHeader) {
                    setShowHeaderTooltip(false);
                }
                else if (isFirstItemCard) {
                    setShowItemCardTooltip(false);
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showInHeader && compareCarsCount > 0) {
            const shouldShowHeaderTooltip = JSON.parse(getSessionStorageItem('show-header-tooltip'));

            if (shouldShowHeaderTooltip) {
                setSessionStorageItem('show-header-tooltip', false);
                setTimeout(() => {
                    setShowHeaderTooltip(false);
                }, headerTooltipTime);
                setShowHeaderTooltip(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareCarsCount]);

    const showCarsAddToCompareNudges = () => {
        const comparePageOpened = JSON.parse(getLocalStorageItem(COMPARE_PAGE_OPENED));

        if (comparePageOpened) {
            return;
        }

        const headerTooltipShownInSession = JSON.parse(getSessionStorageItem('header-tooltip-shown'));
        const headerTooltipShowTotalCount = JSON.parse(getLocalStorageItem('header-tooltip-show-count'));

        if (!headerTooltipShownInSession && headerTooltipShowTotalCount < maxNudgesShowCount) {
            setSessionStorageItem('header-tooltip-shown', true);
            setLocalStorageItem('header-tooltip-show-count', headerTooltipShowTotalCount + 1);
            setSessionStorageItem('show-header-tooltip', true);
        }

        if (compareCarsCount === 1) {
            const comparePopupShown = JSON.parse(getLocalStorageItem('compare-popup-shown'));

            if (!comparePopupShown) {
                setLocalStorageItem('compare-popup-shown', true);
                openComparePopup();
            }
        }

        setLocalStorageItem('item-card-tooltip-show-count', maxNudgesShowCount);
    };

    const onClick = () => {
        if (itemId) {
            if (compareCarsCount === maxCarsToCompare && !compareCars[itemId]) {
                openComparePopup();
                return;
            }
            if (compareCars[itemId]) {
                if (isListingPage) {
                    track(CAR_COMPARE_EVENTS.COMPARE_UNSELECT, {
                        flow_step: CAR_COMPARE_FLOW_STEP.LISTING,
                        select_from: CAR_COMPARE_FLOW_STEP.LISTING,
                        result_count: compareCarsCount - 1,
                        item_id: itemId
                    });
                }

                store.dispatch({ type: 'REMOVE-CAR-FROM-COMPARE', data: { itemId }});
            }
            else {
                if (isListingPage) {
                    track(CAR_COMPARE_EVENTS.COMPARE_SELECT, {
                        flow_step: CAR_COMPARE_FLOW_STEP.LISTING,
                        select_from: CAR_COMPARE_FLOW_STEP.LISTING,
                        result_count: compareCarsCount + 1,
                        item_id: itemId
                    });
                }

                if (showItemCardTooltip) {
                    setShowItemCardTooltip(false);
                }

                showCarsAddToCompareNudges();

                store.dispatch({ type: 'ADD-CAR-TO-COMPARE', data: { itemId, title, price, image }});
            }
        }
        else if (showInHeader) {
            if (compareCarsCount === 0) {
                track(CAR_COMPARE_EVENTS.COMPARE_ERROR, {
                    select_from: flowStep
                });

                setToastMessageState({ isOpen: true, message: <Translation id="addTwoCarsToCompare" />, expiration: 5000, isErrorMessage: true });
            }
            else {
                if (flowStep) {
                    track(CAR_COMPARE_EVENTS.COMPARE_SHOW, {
                        flow_step: flowStep,
                        select_from: flowStep,
                        result_count: compareCarsCount
                    });
                }

                router.push('/compare');
            }
        }
    };

    const getIconUrl = () => {
        if (itemId) {
            return compareCars?.[itemId] ? CAR_COMPARE_ICON_FILLED : CAR_COMPARE_ICON;
        }
        else if (flowStep === CAR_COMPARISION) {
            return CAR_COMPARE_ICON_FILLED;
        }
        return CAR_COMPARE_ICON;
    };

    return (
        <React.Fragment>
            {showItemCardTooltip && <div
                data-aut-id="itemCardTooltip"
                className={ css.itemCardTooltip }>
                <Translation id="addThisCarToCompare" />
            </div>}

            <div className={ classNames(className, {
                [css.headerContainer]: showInHeader
            }) }>
                <ImageWrapper
                    fileName={ `${staticAsseturl}${getIconUrl()}` }
                    data-aut-id="compareIcon"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ onClick }
                    className={ css.compareIcon } />
                {showInHeader && !!compareCarsCount
                    && <div data-aut-id="carsCountBubble" className={ css.carsCountBubble }>{compareCarsCount}</div>}
            </div>

            {showHeaderTooltip && <div className={ css.headerTooltip }>
                <Translation id="seeComparisonList" />
            </div>}
        </React.Fragment>
    );
};

CompareIconCTA.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func
    }).isRequired,
    router: PropTypes.object.isRequired,
    compareCars: PropTypes.object.isRequired,
    track: PropTypes.func,
    setToastMessageState: PropTypes.func,
    showInHeader: PropTypes.bool,
    isListingPage: PropTypes.bool,
    isFirstItemCard: PropTypes.bool,
    itemId: PropTypes.string,
    item: PropTypes.object,
    className: PropTypes.string,
    openComparePopup: PropTypes.func,
    isMyZone: PropTypes.bool,
    flowStep: PropTypes.string,
    categories: PropTypes.object
};

CompareIconCTA.defaultProps = {
    isMyZone: false,
    categories: {}
};

export const mapStateToProps = (state, ownProps) => {
    const compareCars = state.compareCars;
    const item
        = (ownProps.isMyZone && itemSelector(state, ownProps.itemId))
        || state.items.elements?.[ownProps.itemId]
        || {};

    return {
        compareCars,
        item,
        categories: state.categories
    };
};

export const mapDispatchToProps = ({
    setToastMessageState: state => dispatch => dispatch(setToastMessageState(state))
});

export default compose(
    withConfig,
    withTrack,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CompareIconCTA);
