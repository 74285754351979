import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Breadcrumb as RUIBreadcrumb } from 'panamera-react-ui';
import classNames from 'classnames';
import Structured from './Structured';
import css from './Breadcrumb.scss';
import { getBreadCrumbLinks } from 'Helpers/breadcrumb';

export const Breadcrumb = props => {
    const { links, intl, className, Component, 'data-aut-id': dataAutId, ...restProps } = props;
    const breadCrumbLinks = useMemo(() => getBreadCrumbLinks(links, intl), [links, intl]);

    return (
        <React.Fragment>
            <Structured links={ breadCrumbLinks } />
            <div data-aut-id={ dataAutId } >
                <Component
                    { ...restProps }
                    links={ breadCrumbLinks }
                    className={ classNames(css.breadcrumb, className) }
                />
            </div>
        </React.Fragment>
    );
};

Breadcrumb.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    links: PropTypes.array,
    className: PropTypes.string,
    Component: PropTypes.func,
    sessionFeatures: PropTypes.array.isRequired,
    'data-aut-id': PropTypes.string
};

Breadcrumb.defaultProps = {
    links: [],
    Component: RUIBreadcrumb,
    'data-aut-id': 'breadcrumb',
    sessionFeatures: []
};

export default injectIntl(Breadcrumb);
