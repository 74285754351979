export const addFilterPreference = data => ({
    type: 'ADD_FILTER_PREFERENCE',
    data
});

export const clearFiltersPreferences = () => ({
    type: 'CLEAR_FILTER_PREFERENCES'
});

export const setLastListingUrl = data => ({
    type: 'SET_LAST_LISTING_URL',
    data
});

export const verifyUpdateLastListingUrl = (params = {}, pathname = '') => (dispatch, getState) => {
    const { text = '', categoryID = '', geoID = '' } = params;
    const state = getState();
    const listingUrl = `c-${categoryID}_g-${geoID}_t-${text}_p-${pathname}`;

    if (listingUrl !== state.filtersPreferences.lastUrl) {
        dispatch(setLastListingUrl(listingUrl));
        dispatch(clearFiltersPreferences());
    }
};
