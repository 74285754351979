// import React from 'react';
import { compose } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'HOCs/withRouter';
import withTags from 'HOCs/withTags/withTags';
import { routerParamsToSeoQuery } from 'Helpers/listing';
import DefaultTitle from './Title';

export const Title = ({ h1 }) => h1 || <DefaultTitle />;

Title.propTypes = {
    h1: PropTypes.node
};

export default compose(
    withRouter,
    withTags('listing', routerParamsToSeoQuery, { withHelmet: false })
)(Title);
