import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'panamera-react-ui';

export const CustomLinkRender = ({ href, children, ...rest }) => <Link to={ href } { ...rest }>{children}</Link>;

CustomLinkRender.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
