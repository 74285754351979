import { MAIN_CATEGORY } from './categories';

export const QUESTION_SEO = {
    MAIN_ENTITY: 'mainEntity',
    ITEM_TYPE: 'https://schema.org/Question',
    ITEM_PROP: 'name'
};

export const ANSWER_SEO = {
    ITEM_TYPE: 'https://schema.org/Answer',
    ITEM_PROP: 'acceptedAnswer'
};

export const MAKE_KEY = 'make';

const commonContentType = {
    CATEGORY_L1_LOCATION_L3: 'category_l1_location_l3',
    CATEGORY_L2_LOCATION_L3: 'category_l2_location_l3'
};

const GENERAL = 'general';
const LOCATION_L1 = 'location_l1';
const LOCATION_L3 = 'location_l3';
const LOCATION_L3_FURNISHING = 'location_l3_furnishing';
const LOCATION_L3_BRAND = 'location_l3_brand';

export const FAQS = {
    [MAIN_CATEGORY.BIKES]: {
        LOCATION_L1,
        LOCATION_L3
    },
    [MAIN_CATEGORY.ELECTRONICS]: commonContentType,
    [MAIN_CATEGORY.REAL_ESTATE]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY.JOBS]: {
        ...commonContentType,
        GENERAL
    },
    [MAIN_CATEGORY.MOBILES]: {
        LOCATION_L1,
        LOCATION_L3
    },
    [MAIN_CATEGORY.MOBILE_PHONES]: {
        LOCATION_L3,
        LOCATION_L3_BRAND
    },
    [MAIN_CATEGORY.ACCESSORIES]: {
        LOCATION_L3,
        LOCATION_L3_TYPE: 'location_l3_type'
    },
    [MAIN_CATEGORY.TABLETS]: {
        LOCATION_L3
        // As per this task https://sobek.atlassian.net/browse/OLXIN-1992 we are removing Type filter.
        // LOCATION_L3_TYPE: 'location_l3_type'
    },
    [MAIN_CATEGORY.CARS]: {
        GENERAL,
        WITHOUT_BRAND_WITH_LOCATION: 'without_brand_with_location',
        BRAND_WITHOUT_LOCATION: 'brand_without_location',
        BRAND_WITH_LOCATION: 'brand_with_location'
    },
    [MAIN_CATEGORY.HOUSES_FOR_SALE]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY.HOUSES_FOR_RENT]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY.LANDS_AND_PLOTS]: {
        LOCATION_L3,
        LOCATION_L3_FOR_SALE: 'location_l3_for_sale',
        LOCATION_L3_FOR_RENT: 'location_l3_for_rent'
    },
    [MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_RENT]: {
        LOCATION_L3,
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_SALE]: {
        LOCATION_L3,
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY.PG_AND_GUEST_HOUSES]: {
        LOCATION_L3,
        LOCATION_L3_SUBTYPE: 'location_l3_subtype',
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY.MOTORCYCLES]: {
        LOCATION_L1,
        LOCATION_L3,
        LOCATION_L3_BRAND,
        LOCATION_L3_MODEL: 'location_l3_model'
    },
    [MAIN_CATEGORY.SCOOTERS]: {
        LOCATION_L3,
        LOCATION_L3_BRAND,
        LOCATION_L3_MODEL: 'location_l3_model'
    },
    [MAIN_CATEGORY.BICYCLES]: {
        LOCATION_L3,
        LOCATION_L3_BRAND
    }
};

export const FAQ_TRACK = {
    FAQ_CLICKED: 'faq_clicked',
    FAQ_DISPLAYED: 'faq_displayed',
    LISTING_RESULTS: 'listings_results'
};
