import { MAIN_CATEGORY } from 'Constants/categories';
import { FAQS } from 'Constants/faq';

import { categoriesByFilterSelector } from 'Selectors/categories';
import { getAbundanceFilterInfo, getAppliedFiltersLabels, getParsedAppliedFilters } from 'Selectors/filtersTanak';
import { getPopularLocationsLeftBar } from 'Selectors/popularLocations';

import { getFilteredNestedSelect } from 'Helpers/filtersTanak';

const TOP_CONTENT = 3;
const L3_LOCATION = 'CITY';

export const LOCATION_LEVELS = {
    NEIGHBOURHOOD: 'location_l4',
    CITY: 'location_l3',
    STATE: 'location_l2',
    COUNTRY: 'location_l1'
};

function getTopContent(input) {
    const data = [...input];
    const last = data.pop();
    const result = data.length === 0 ? last : `${data.join(', ') } and ${ last}`;

    return result || '';
}

function getFormattedNumber(intl, count) {
    return intl?.formatNumber(count);
}

function getTotalCount(categoryFilters, intl, category, sessionFeatures = []) {
    const flag = sessionFeatures?.includes('olxin-4485');
    let totalCount = getTotalInventoryForCategory(categoryFilters);
    let categorySpecificCount = category?.key || category?.id
        ? categoryFilters?.sub_categories?.find(b => {
            if (category?.key) {
                return b?.key === category?.key;
            }

            if (category?.id) {
                return b?.id === category?.id;
            }

            return false;
        })?.count || 0
        : 0;

    if (flag) {
        totalCount = totalCount > 0 ? String(totalCount) : undefined;
        categorySpecificCount = categorySpecificCount > 0 ? String(categorySpecificCount) : undefined;
    }
    else {
        totalCount = intl && totalCount ? getFormattedNumber(intl, totalCount) : undefined;
        categorySpecificCount = intl && categorySpecificCount ? getFormattedNumber(intl, categorySpecificCount) : undefined;
    }

    return { categorySpecificCount, totalCount };
}

function getSelectedFilter(selectedFilters, key) {
    return selectedFilters?.find(f => f.filterId === key);
}

export function getTotalInventoryForCategory(categories) {
    if (categories && categories?.sub_categories) {
        return categories?.sub_categories?.reduce((acc, curr) => (acc += curr?.count || 0), 0);
    }

    return 0;
}

export function getContentForBikesCategory(
    categoryId,
    {
        categoryFilters,
        intl,
        locations,
        selectedFilters,
        selectedLocation,
        sessionFeatures,
        topModels
    }
) {
    const cat = getType(categoryId);
    const TYPE = FAQS[MAIN_CATEGORY[cat]] || {};
    const make = getSelectedFilter(selectedFilters, 'make');
    const model = getSelectedFilter(selectedFilters, 'model');
    const popular_areas = getTopContent(getPopularLocations(locations) || []);

    const content = {
        number_of_bicycles: getTotalCount(categoryFilters, intl, { key: 'bicycles' }, sessionFeatures).categorySpecificCount,
        number_of_bikes: getTotalCount(categoryFilters, intl, undefined, sessionFeatures).totalCount,
        number_of_scooters: getTotalCount(categoryFilters, intl, { key: 'scooters' }, sessionFeatures).categorySpecificCount,
        make: make?.label || undefined,
        model: model?.label || undefined,
        top_models: getTopContent((topModels || []).reduce((acc, curr) => acc.push(curr.name) && acc, [])),
        popular_areas
    };

    const isMakeFilterApplied = Boolean(make?.id);
    const isModelFilterApplied = Boolean(model?.id);

    let type;

    if (selectedLocation?.type === L3_LOCATION) {
        if (categoryId === MAIN_CATEGORY.MOTORCYCLES || categoryId === MAIN_CATEGORY.SCOOTERS) {
            if (isModelFilterApplied) {
                type = TYPE.LOCATION_L3_MODEL;
            }
            else if (isMakeFilterApplied) {
                type = TYPE.LOCATION_L3_BRAND;
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
        else if (categoryId === MAIN_CATEGORY.BICYCLES) {
            if (isMakeFilterApplied) {
                type = TYPE.LOCATION_L3_BRAND;
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
        else {
            type = TYPE.LOCATION_L3;
        }
    }
    else if (selectedLocation?.type === 'COUNTRY') {
        type = TYPE.LOCATION_L1;
    }

    return { content, type };
}

export function getContentForElectronicsCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        minPrice,
        selectedLocation,
        sessionFeatures
    }
) {
    const flag = sessionFeatures?.includes('olxin-4485');
    const TYPE = FAQS[MAIN_CATEGORY.ELECTRONICS];
    const topTypes = getTopContent(categoryFilters?.sub_categories
        ?.sort((a, b) => b.count - a.count)
        .map(i => i.name)
        .slice(0, TOP_CONTENT) || []);
    const totalNumber = category?.id === parentId
        ? getTotalCount(categoryFilters, intl, undefined, sessionFeatures).totalCount
        : getTotalCount(categoryFilters, intl, { id: category?.id }, sessionFeatures).categorySpecificCount;

    const finalMinPrice = flag ? String(minPrice) : intl?.formatNumber(minPrice || 0);

    const content = {
        number_of_items: totalNumber,
        top_models: topTypes,
        type: category?.name,
        starting_price: minPrice > 0 ? finalMinPrice : undefined
    };

    const categoryContent = category?.id === parentId
        ? TYPE.CATEGORY_L1_LOCATION_L3
        : TYPE.CATEGORY_L2_LOCATION_L3;

    return {
        content,
        type: selectedLocation?.type === L3_LOCATION ? categoryContent : ''
    };
}

function getPopularLocations(locations) {
    if (locations && locations?.length > 0 && locations[0].selected) {
        return locations[0]?.list?.sort((a, b) => b.count - a.count).map(i => i.title || '').slice(0, TOP_CONTENT);
    }
    else if (locations?.[0]?.list) {
        return getPopularLocations(locations?.[0]?.list);
    }

    return [];
}

export function getContentForRealEstateCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        locations,
        minPrice,
        plotSize,
        selectedFilters,
        selectedLocation,
        sessionFeatures
    }
) {
    const flag = (sessionFeatures || [])?.includes('olxin-4485');
    const cat = getType(category?.id);
    const TYPE = FAQS[MAIN_CATEGORY[cat]] || {};
    const popular_areas = getTopContent(getPopularLocations(locations) || []);
    const totalNumber = category?.id === parentId
        ? getTotalCount(categoryFilters, intl, undefined, sessionFeatures).totalCount
        : getTotalCount(categoryFilters, intl, { id: category?.id }, sessionFeatures).categorySpecificCount;
    const filterFurnishing = getSelectedFilter(selectedFilters, 'furnished');
    const filterSubtype = getSelectedFilter(selectedFilters, 'subtype');
    const filterType = getSelectedFilter(selectedFilters, 'type');

    let type;

    if (selectedLocation?.type === L3_LOCATION) {
        if (category?.id === MAIN_CATEGORY.LANDS_AND_PLOTS) {
            if (filterType?.props.id === 'for-rent') {
                type = TYPE.LOCATION_L3_FOR_RENT;
            }
            else if (filterType?.props.id === 'for-sale') {
                type = TYPE.LOCATION_L3_FOR_SALE;
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
        else if (category?.id === MAIN_CATEGORY.PG_AND_GUEST_HOUSES && Boolean(filterFurnishing) && filterFurnishing?.props?.id === 'yes') {
            type = TYPE.LOCATION_L3_FURNISHING;
        }
        else if (
            (category?.id === MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_RENT || category?.id === MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_SALE)
            && Boolean(filterFurnishing)
        ) {
            type = TYPE.LOCATION_L3_FURNISHING;
        }
        else if (category?.id === MAIN_CATEGORY.PG_AND_GUEST_HOUSES && Boolean(filterSubtype)) {
            type = TYPE.LOCATION_L3_SUBTYPE;
        }
        else if (!filterSubtype && !filterFurnishing && !filterType) {
            type = TYPE.LOCATION_L3;
        }
    }

    const finalMinPrice = flag ? String(minPrice) : intl?.formatNumber(minPrice);

    return {
        content: {
            number: totalNumber,
            popular_areas,
            starting_price: minPrice > 0 ? finalMinPrice : undefined,
            min_plot_size: plotSize?.min || undefined,
            max_plot_size: plotSize?.max || undefined,
            furnishing: filterFurnishing?.label || undefined,
            subtype: filterSubtype?.label || undefined
        },
        type
    };
}

export function getContentForJobsCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        selectedLocation,
        sessionFeatures
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.JOBS];
    const topTypes = categoryFilters?.sub_categories?.sort((a, b) => b.count - a.count).map(i => i.name).slice(0, TOP_CONTENT);
    const totalNumber = category?.id === parentId
        ? getTotalCount(categoryFilters, intl, undefined, sessionFeatures).totalCount
        : getTotalCount(categoryFilters, intl, { id: category?.id }, sessionFeatures).categorySpecificCount;
    const categoryContent = category?.id === parentId ? TYPE.CATEGORY_L1_LOCATION_L3 : TYPE.CATEGORY_L2_LOCATION_L3;

    return {
        content: {
            category: category?.name,
            job_types: getTopContent(topTypes || []),
            popular_jobs: getTopContent(topTypes || []),
            number: totalNumber
        },
        type: selectedLocation?.type === L3_LOCATION ? categoryContent : TYPE.GENERAL
    };
}

export function getContentForMobilesCategory(
    category,
    parentId,
    {
        categoryFilters,
        selectedFilters,
        intl,
        selectedLocation,
        sessionFeatures
    }
) {
    const cat = getType(category?.id);
    const TYPE = FAQS[MAIN_CATEGORY[cat]] || {};
    let totalNumber = getTotalCount(categoryFilters, intl, undefined, sessionFeatures).totalCount;

    if (category?.id !== parentId) {
        totalNumber = getTotalCount(categoryFilters, intl, { id: category?.id }).categorySpecificCount;
    }

    const make = getSelectedFilter(selectedFilters, 'make');
    const deviceType = getSelectedFilter(selectedFilters, 'type');
    const isMakeFilterApplied = Boolean(make?.id);
    const isTypeFilterApplied = Boolean(deviceType?.id);

    let type;

    if (selectedLocation?.type === L3_LOCATION) {
        if (category?.id === parentId) {
            type = TYPE.LOCATION_L3;
        }
        else if (category?.id === MAIN_CATEGORY.MOBILE_PHONES) {
            if (isMakeFilterApplied) {
                type = TYPE.LOCATION_L3_BRAND;
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
        else if (category?.id === MAIN_CATEGORY.ACCESSORIES && isTypeFilterApplied) {
            type = TYPE.LOCATION_L3_TYPE;
        }
        else {
            type = TYPE.LOCATION_L3;
        }
    }
    else if (selectedLocation?.type === 'COUNTRY') {
        type = TYPE.LOCATION_L1;
    }

    return {
        content: {
            make: make?.label || undefined,
            number: totalNumber === '0' ? undefined : totalNumber,
            ...(isTypeFilterApplied ? { selected_filter: deviceType?.label || undefined } : {})
        },
        type
    };
}

export function getContentForCarsCategory(
    {
        selectedFilters,
        params: { geoID } = { geoID: undefined }
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.CARS];
    const make = getSelectedFilter(selectedFilters, 'make');
    const content = {
        make: make?.label || ''
    };

    const isMakeFilterApplied = Boolean(make?.id);
    let type;

    if (geoID && !isMakeFilterApplied) {
        type = TYPE.WITHOUT_BRAND_WITH_LOCATION;
    }
    else if (!geoID && isMakeFilterApplied) {
        type = TYPE.BRAND_WITHOUT_LOCATION;
    }
    else if (geoID && isMakeFilterApplied) {
        type = TYPE.BRAND_WITH_LOCATION;
    }
    else {
        type = TYPE.GENERAL;
    }

    return { content, type };
}

function getType(id) {
    let type = null;

    for (const [k, v] of Object.entries(MAIN_CATEGORY)) {
        if (id === v) {
            type = k;
            break;
        }
    }

    return type;
}

function interpolateText(text = '', types = {}) {
    if (types && Object.entries(types).length > 0) {
        for (const [key, value] of Object.entries(types)) {
            if (text) {
                text = text.replaceAll(`{${key}}`, value);
            }
        }
    }

    return text;
}

function interpolateLocation(text = '', location = '') {
    if (location && text) {
        text = text.replaceAll('{location}', location);
    }

    return text ?? '';
}

export function interpolate(text, location, content) {
    const textWithLocation = interpolateLocation(text, location);

    return interpolateText(textWithLocation, content);
}

export function getTransformedMakeModels(filter) {
    let topModels = [];

    const values = filter?.render?.customConfiguration?.values || [];

    if (filter.render && values?.length > 0) {
        const {
            render: {
                customConfiguration: { valuesInfo } = {}
            } = {}
        } = filter;

        topModels = Object.values(valuesInfo || {})
            .filter(v => v?.abundance?.count)
            .sort((v1, v2) => v2.abundance.count - v1.abundance.count)
            .map(v => ({ name: v.name }))
            .slice(0, TOP_CONTENT);
    }

    return topModels;
}

export function getMinimumPrice({ items: { elements }}) {
    let min = 0;

    for (const val of Object.values(elements || {})) {
        const minVal = min > val?.price?.value?.raw ? val.price.value.raw : min;

        min = min === 0 ? (val?.price?.value?.raw || 0) : minVal;
    }

    return min;
}

export function getMultipleFilterSelected(selectedFilters) {
    return selectedFilters?.filter(i => i.filterId === 'make')?.length > 1
    || selectedFilters?.filter(i => i.filterId === 'model')?.length > 1
    || selectedFilters?.filter(i => i.filterId !== 'make' && i.filterId !== 'model')?.length > 1;
}

export function getPlotSize({ items: { elements }}) {
    let min = 0;
    let max = 0;

    for (const { parameters } of Object.values(elements || {})) {
        const yd = parseInt((parameters || [])?.find(p => p?.key === 'yd')?.value || '0', 10);
        const minVal = min > yd ? yd : min;
        const maxVal = max < yd ? yd : max;

        min = min === 0 ? yd : minVal;
        max = max === 0 ? yd : maxVal;
    }

    return { min, max };
}

const getParams = props => ({
    categoryId: props?.params?.categoryID,
    locationId: props?.params?.geoID,
    search: props?.params?.text,
    params: props?.location?.query,
    constructionStatus: props?.params?.constructionStatus,
    type: props?.type || undefined
});

export function getPayloadForFAQ(state, renderProps, config, sessionFeatures = []) {
    const { location, params } = renderProps;
    const { categories, locations } = state;

    const categoryId = params?.categoryID;
    const category = categories?.elements[categoryId];
    const categoriesInfo = categoriesByFilterSelector(state);
    const parentCategoryId = category?.parent_id === 0 ? category?.id : category?.parent_id;

    // get top popular locations based on inventory count.
    const popularLocationList = getPopularLocationsLeftBar(state, getParams({ location, params }));

    // get minimum price based on items listing.
    const minPrice = getMinimumPrice(state);

    // get min & max plot size based on items listing.
    const plotSize = getPlotSize(state);

    // get top models based on inventory count.
    const filtersInfoByAttr = getAbundanceFilterInfo(state, categoryId, config);
    const appliedFiltersInfo = getParsedAppliedFilters(state, categoryId);
    const transformedFilterInfo = getFilteredNestedSelect(filtersInfoByAttr?.make || {}, filtersInfoByAttr, '', appliedFiltersInfo);

    const payload = {
        category,
        // get all the sub_categories inventory count for a specific category.
        categoryFilters: categoriesInfo.find(cf => cf?.id === parentCategoryId),
        minPrice,
        parentCategoryId,
        plotSize,
        locations: popularLocationList,
        // this selector will always return empty array, to resolve this we are passing
        // appliedFiltersInfo as the third argument.
        selectedFilters: getAppliedFiltersLabels(state, categoryId, Object.keys(appliedFiltersInfo || {}) || [], sessionFeatures),
        selectedLocation: locations?.selectedLocation,
        sessionFeatures,
        locationLevel: LOCATION_LEVELS[locations?.selectedLocation?.type],
        topModels: getTransformedMakeModels(transformedFilterInfo?.model || {})
    };

    return payload;
}

export function getFaqDynamicValues(category, parentCategoryId, restProps) {
    switch (parentCategoryId) {
        case MAIN_CATEGORY.BIKES:
            return getContentForBikesCategory(category.id, restProps);
        case MAIN_CATEGORY.ELECTRONICS:
            return getContentForElectronicsCategory(category, parentCategoryId, restProps);
        case MAIN_CATEGORY.JOBS:
            return getContentForJobsCategory(category, parentCategoryId, restProps);
        case MAIN_CATEGORY.REAL_ESTATE:
            return getContentForRealEstateCategory(category, parentCategoryId, restProps);
        case MAIN_CATEGORY.MOBILES:
            return getContentForMobilesCategory(category, parentCategoryId, restProps);
        case MAIN_CATEGORY.CAR:
            return getContentForCarsCategory({ params: restProps.params, selectedFilters: restProps.selectedFilters });
        default: return { content: {}, type: FAQS.NOOP };
    }
}

function shouldInterpolateContent(payload, terms) {
    let result = true;

    for (const term of terms) {
        if (Array.isArray(payload[term]) && payload[term]?.length === 0 || typeof payload[term] === 'string' && (!payload[term] || payload[term] === '0')) {
            result = false;
        }
    }

    return result;
}

export function interpolateContent(faqContent, restProps, fn = getFaqDynamicValues) {
    let title = '';
    const content = [];

    if (faqContent?.title && faqContent?.content?.length > 0) {
        title = interpolate(faqContent?.title, restProps.selectedLocation?.name, fn()?.content);

        for (const c of (faqContent?.content || [])) {
            const typeContent = fn();
            const payload = typeContent?.content;
            const regex = new RegExp(/\{(top_models|popular_areas|job_types|number_of_bikes|number_of_scooters|number_of_bicycles|number_of_items|number|model)\}/, 'g');
            const interpolateTerm = /\{(\w+)\}/g;

            if (regex.test(c.a) && typeContent?.type) {
                const terms = c.a.match(interpolateTerm).map(m => m.replace(/\{(\w+)\}/g, '$1'));
                const shouldInterpolate = shouldInterpolateContent(payload, terms);

                if (shouldInterpolate) {
                    content.push({
                        ...(c?.list ? { list: c.list } : {}),
                        q: interpolate(c?.q, restProps.selectedLocation?.name, payload),
                        a: interpolate(c?.a, restProps.selectedLocation?.name, payload)
                    });
                }
            }
            else if (typeContent?.type) {
                content.push({
                    ...(c?.list ? { list: c.list } : {}),
                    q: interpolate(c?.q, restProps.selectedLocation?.name, payload),
                    a: interpolate(c?.a, restProps.selectedLocation?.name, payload)
                });
            }
        }
    }

    return { content, title };
}
