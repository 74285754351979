import { gpsSelector } from './location';

const GPS_SORTING_KEYS = ['asc-distance', 'desc-distance'];

export const sortingOptionsSelector = (state, { params }) => {
    const { sortingOptions } = state;
    const { latitude, longitude } = gpsSelector(state) || {};
    let filters;

    if (sortingOptions && sortingOptions.data && params && params.categoryID) {
        filters = sortingOptions.data[params.categoryID];
    }
    else if (sortingOptions && sortingOptions.metadata) {
        filters = sortingOptions.metadata.default_sorting_group;
    }

    if (filters && filters.values && !(latitude && longitude)) {
        filters = {
            ...filters,
            values: filters.values.filter(({ key }) => !GPS_SORTING_KEYS.includes(key))
        };
    }

    return filters;
};

export const currentSortingSelector = (_, { location }) => location && location.query && location.query.sorting;
