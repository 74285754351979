import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import { DropdownMenu, css as cssReactUI } from 'panamera-react-ui';
import css from './SortingContainer.desktop.scss';
import * as urlHelper from 'Helpers/url';
import * as filtersHelper from 'Helpers/filters';
import withRouter from 'HOCs/withRouter';
import classNames from 'classnames';
import AdsResult from './AdsResult';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SearchHint from './SearchHint';
import { getGeolocationPosition } from 'Actions/categoryBrowsing';
import GpsBlockedModalMessage from 'Components/PermissionModals/GpsBlocked/GpsBlocked';
import { noop } from 'Helpers/function';
import { itemsFirstSectionOffsetSelector, suggestedItemsFirstSectionOffsetSelector } from 'Selectors/items';
import { SUGGESTED_ITEMS, ORIGINAL_ITEMS } from 'Constants/items';
import withSorting from 'HOCs/withSorting/withSorting';
import { withConfig } from 'HOCs/withConfig/withConfig';

const { icons } = cssReactUI;

export class SortingContainer extends React.PureComponent {
    static propTypes = {
        total: PropTypes.number,
        isSortingOpen: PropTypes.bool,
        location: PropTypes.object,
        router: PropTypes.object,
        sortingOptions: PropTypes.array,
        trackListingSelectSorting: PropTypes.func,
        trackListingApplySorting: PropTypes.func,
        visualizationType: PropTypes.string,
        currentSorting: PropTypes.string,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        firstSectionOffset: PropTypes.number,
        labelFlag: PropTypes.bool,
        labelType: PropTypes.string,
        label: PropTypes.string,
        itemsMetadata: PropTypes.object };

    static defaultProps = {
        total: 0,
        isSortingOpen: true,
        location: {},
        router: {},
        sortingOptions: [],
        trackListingSelectSorting: noop,
        trackListingApplySorting: noop,
        toggleSorting: noop,
        visualizationType: 'grid',
        showControlBar: true,
        firstSectionOffset: null,
        itemsMetadata: {}};

    constructor(props) {
        super(props);

        this.state = {
            isSortingOpen: false,
            showGpsBlockedMessage: false
        };

        this.carCategory = props.marketConfig.get('adpvAuto', 'carCategory');
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSortingOpen !== prevProps.isSortingOpen && this.props.isSortingOpen) {
            this.props.trackListingSelectSorting({ select_from: 'listing' });
        }
    }

    getActionable() {
        const appliedSorting = this.props.sortingOptions && this.props.sortingOptions.find(sort => sort.code === this.props.currentSorting);

        const sortingTitle = (
            <div className={ css.sortingTitle }>
                <span className={ css.title }><Translation id="sort_by" /></span>
                <span className={ css.selected }> {`: ${appliedSorting && appliedSorting.name}`}</span>
                <span className={ `${css.arrow} ${icons.panameraIcons} ${icons['icon-ArrowDown']} ${this.state.isSortingOpen ? css.opened : ''}` } />
            </div>
        );

        if (!appliedSorting?.name) {
            return <></>;
        }
        return sortingTitle;
    }

    handleCloseGpsMessage = () => this.setState({ showGpsBlockedMessage: false });

    getUrlBySorting = sortingValue => {
        const { location } = this.props;
        const { query } = location || {};
        const filters = query.filter ? filtersHelper.decodeFilters(query.filter) : {};
        const locationWithSorting = {
            ...location,
            query: {
                ...query,
                sorting: sortingValue
            }
        };

        return urlHelper.urlPathCreate(filters, locationWithSorting);
    }

    sortingByGpsPosition = sortingValue => {
        getGeolocationPosition().then(() => {
            this.props.trackListingApplySorting({ order: sortingValue });
            const sortingUrl = this.getUrlBySorting(sortingValue);

            return this.props.router.push(sortingUrl);
        }).catch(() => this.setState({ showGpsBlockedMessage: true }));
    };

    handleSorting = sortingValue => {
        if (sortingValue === 'asc-distance' || sortingValue === 'desc-distance') {
            return this.sortingByGpsPosition(sortingValue);
        }
        this.props.trackListingApplySorting({ order: sortingValue });
        const sortingUrl = this.getUrlBySorting(sortingValue);

        return this.props.router.push(sortingUrl);
    }

    handleSortingMenu = ({ opened: isSortingOpen }) => {
        this.setState({ isSortingOpen });
        if (isSortingOpen) {
            this.props.trackListingSelectSorting({ select_from: 'listing' });
        }
    };

    isActive = visualizationType => {
        return this.props.visualizationType === visualizationType;
    }

    renderSorting = () => {
        const sortingList = this.props.sortingOptions.map((sort, code) => {
            const onClick = () => this.handleSorting(sort.code);

            return (
                <div
                    key={ code }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ onClick }
                    className={ css.list }
                >
                    { sort.code === this.props.currentSorting && <span className={ `${css.sortApplied} ${icons.panameraIcons} ${icons['icon-OK']}` } />}
                    <span key={ code } className={ css.sortingName }>
                        {sort.name}
                    </span>
                </div>
            );
        });

        if (sortingList && sortingList.length) {
            return (
                <DropdownMenu
                    actionable={ this.getActionable() }
                    className={ css.sortingWrapper }
                    listClassName={ css.dropDownList }
                    onClick={ this.handleSortingMenu }
                >
                    { sortingList}
                </DropdownMenu>
            );
        }
        return null;
    }

    render() {
        const {
            total,
            firstSectionOffset,
            labelFlag,
            labelType,
            label,
            itemsMetadata,
            location,
            marketConfig
        } = this.props;

        const {
            hint,
            original_term,
            show_hint,
            suggested_term
        } = itemsMetadata;

        const { showGpsBlockedMessage } = this.state;
        const totalValue = firstSectionOffset === null ? total : firstSectionOffset;
        const showAdsResult = totalValue > 0;
        const categoryId = '';

        return (
            <div className={ classNames(css.wrapper, { [css.listingHeaderWithBorder]: showAdsResult }) }>
                <div className={ css.flexItem }>
                    {show_hint
                        && <SearchHint
                            hintText={ hint }
                            hintTerm={ suggested_term }
                            location={ location }
                            categoryId={ categoryId }
                        />
                    }
                    <div className={ css.listingHeader }>
                        {(labelFlag || showAdsResult)
                            && <AdsResult
                                className={ css.listingHeaderTitle }
                                labelFlag={ labelFlag }
                                labelType={ labelType }
                                label={ label }
                                term={ labelType === ORIGINAL_ITEMS ? original_term : suggested_term }
                                total={ total }
                                itemsMetadata={ itemsMetadata }
                                marketConfig={ marketConfig }
                            />
                        }
                        {showGpsBlockedMessage && <GpsBlockedModalMessage onClose={ this.handleCloseGpsMessage } />}
                    </div>
                </div>
                { this.renderSorting() }
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const { labelType } = ownProps;

    return {
        isSortingOpen: state.toggleSorting,
        firstSectionOffset: (labelType === SUGGESTED_ITEMS) ? suggestedItemsFirstSectionOffsetSelector(state) : itemsFirstSectionOffsetSelector(state)
    };
};

export default compose(
    connect(mapStateToProps, null),
    withRouter,
    withConfig('marketConfig'),
    withSorting)(SortingContainer);
