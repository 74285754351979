import { urls, TYPE } from 'Reducers/faq';

export const getFaq = (categoryId, type, filters = {}) => (dispatch, getState, { post }) => {
    const state = getState();

    const { categories, locations } = state;
    const category = categories?.elements[categoryId];

    let level = null;

    if (locations?.selectedLocation?.type === 'CITY') {
        level = 'location_l3';
    }
    else if (locations?.selectedLocation?.type === 'COUNTRY') {
        level = 'location_l1';
    }

    const location = {
        level,
        name: locations?.selectedLocation?.name
    };

    const actionType = `ELEMENT_${TYPE}`;
    const url = urls.getFaqV2();

    return dispatch(post(url, actionType, {
        parentCategoryId: category?.parent_id,
        categoryId: category?.id,
        filters,
        location,
        type
    }));
};
