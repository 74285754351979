/* eslint-disable react-perf/jsx-no-new-array-as-prop, no-magic-numbers */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Link } from 'panamera-react-ui';
import { compose } from 'redux';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';

import withTrack from 'HOCs/withTrack/withTrack';
import { buildURL } from 'Helpers/url';
import { DYNAMIC_CONTENT } from 'Constants/tracking';
import { CATEGORIES_IDS, MAIN_CATEGORY } from 'Constants/categories';

import css from './DynamicContent.APP_TARGET.scss';
import { isMobile } from 'Helpers/devices';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
import { CustomLinkRender } from './CustomLink';

const { BIKES, MOBILES, JOBS, MOBILE_PHONES, MOTORCYCLES, REAL_ESTATE, SCOOTERS, TABLETS } = MAIN_CATEGORY;
const categories = [REAL_ESTATE, JOBS, MOBILES, BIKES];

const brandRegex = /\[\{popular_brands\}\]/g;
const modelRegex = /\[\{popular_models\}\]/g;
const customLinkRender = { a: CustomLinkRender };

function handleLinks({ data, getUrl, regex, text }) {
    const finalData = [...data];
    const lastWord = finalData?.pop();

    const finalText = text
        .replace(
            regex,
            `${finalData
                ?.map(data => `<a rel='' data-aut-id='link' aria-current='page' class='' href='${getUrl(data)}'>${data?.name}</a>`)
                ?.join(', ')} ${finalData?.length > 0 ? lastWord?.prefix : ''} <a rel='' data-aut-id='link' aria-current='page' class='' href='${getUrl(lastWord)}'>${lastWord?.name}</a>`
        );

    return finalText;
}

const DynamicContent = ({ category, content, filter, selectedLocation, sessionFeatures, track }) => {
    const [open, setOpen] = React.useState(false);
    const { brand_data, footer_title, model_data, table_data, table_headers, table_title } = content || {};
    const dynamicContentFlag = (sessionFeatures || [])?.includes('olxin-4520');
    let { footer_text, intro_text } = content || {};

    const getUrl = ({ filter_type, id }) => buildURL({
        category,
        location: selectedLocation,
        params: {
            filter: {
                [filter_type]: id
            }
        }
    });

    if (dynamicContentFlag) {
        if (brandRegex.test(intro_text) && brand_data?.length > 0) {
            intro_text = handleLinks({ data: brand_data, getUrl, regex: brandRegex, text: intro_text });
        }

        if (modelRegex.test(intro_text) && model_data?.length > 0) {
            intro_text = handleLinks({ data: model_data, getUrl, regex: modelRegex, text: intro_text });
        }

        if (brandRegex.test(footer_text) && brand_data?.length > 0) {
            footer_text = handleLinks({ data: brand_data, getUrl, regex: brandRegex, text: footer_text });
        }

        if (modelRegex.test(footer_text) && model_data?.length > 0) {
            footer_text = handleLinks({ data: model_data, getUrl, regex: modelRegex, text: footer_text });
        }
    }

    function handleClick() {
        setOpen(!open);
        handleTrack('read_more')();
    }

    function getPayloadForUrl(row) {
        if (categories.indexOf(category?.id) > -1) {
            return {
                category: {
                    id: row.brand_id,
                    name: row.brand
                },
                location: selectedLocation
            };
        }
        else if (CATEGORIES_IDS.CARS.includes(category.id) || (dynamicContentFlag && [MOBILE_PHONES, MOTORCYCLES, SCOOTERS, TABLETS].indexOf(category?.id) > -1)) {
            return {
                category,
                location: selectedLocation,
                params: {
                    filter: {
                        ...filter,
                        ...(filter.make
                            ? { model: row?.brand_id || undefined }
                            : { make: row?.brand_id || undefined }
                        )
                    }
                }
            };
        }

        return {};
    }

    function getPageType() {
        if (['COUNTRY', 'CITY'].indexOf(selectedLocation?.type) > -1) {
            if (CATEGORIES_IDS.CARS.includes(category.id)) {
                return filter?.make ? 'dc_model_page' : 'dc_brand_page';
            }

            return selectedLocation?.type === 'COUNTRY' ? 'dc_india_page' : 'dc_location_page';
        }

        return {};
    }

    function handleTrack(type) {
        return () => {
            track(type === 'link' ? DYNAMIC_CONTENT.CLICKED_DC : DYNAMIC_CONTENT.CLICKED_READ_MORE, {
                category_id: category?.id,
                dc_page_type: getPageType()
            });
        };
    }

    // this function is added to resolve the ' no-nested-ternary ' issue and will be removed when removing the session feature dynamicContentFlag
    function renderEmptyIntroTextContainer() {
        return <div className={ css.emptyIntroTextContainer } />;
    }

    return (
        <div className={ classnames(css.dynamicContentContainer, { [css.dynamicContentContainerV1]: dynamicContentFlag }) }>
            {
                intro_text
                    ? <div className={ classnames(css.introTextContainer, { [css.introTextContainerV2]: dynamicContentFlag }) }>
                        <ReactMarkdown
                            components={ customLinkRender }
                            className={ classnames(css.introText, { [css.clipText]: !open, [css.noClipText]: isMobile && !open, [css.introTextV2]: dynamicContentFlag, [css.clipTextV2]: dynamicContentFlag && !open }) }
                            rehypePlugins={ [rehypeRaw] }
                        >
                            {intro_text}
                        </ReactMarkdown>
                        <strong className={ classnames(css.expander, { [css.hide]: open, [css.expanderV2]: dynamicContentFlag }) } onClick={ handleClick }>
                            <Translation id="read_more" />
                        </strong>
                    </div>
                    : renderEmptyIntroTextContainer()
            }

            {
                table_title && open
                    ? <ReactMarkdown className={ css.introTitle } rehypePlugins={ [rehypeRaw] }>{table_title}</ReactMarkdown>
                    : undefined
            }

            {table_headers?.length > 0 ? (
                <table className={ classnames({ [css.hide]: !open }) }>
                    <thead>
                        <tr>
                            {table_headers.map((col, idx) => (
                                <th key={ idx }>
                                    <ReactMarkdown rehypePlugins={ [rehypeRaw] }>{col.display_name}</ReactMarkdown>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table_data.map((row, idx) => (
                            <tr key={ idx }>
                                {table_headers.map((col, idx) => {
                                    const url = buildURL(getPayloadForUrl(row));

                                    return (
                                        <td key={ idx }>
                                            {col?.type === 'hyperLink' ? <Link data-aut-id={ 'link' } onClick={ handleTrack('link') } to={ url }>{row[col.key]}</Link> : row[col.key]}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : undefined}

            {
                footer_title
                    ? <ReactMarkdown className={ classnames(css.introTitle, css.footerTitle, { [css.hide]: !open }) } rehypePlugins={ [rehypeRaw] }>{footer_title}</ReactMarkdown>
                    : undefined
            }
            {
                footer_text
                    ? <ReactMarkdown
                        components={ customLinkRender }
                        className={ classnames(css.introText, css.footerText, { [css.hide]: !open, [css.introTextV2]: dynamicContentFlag }) }
                        rehypePlugins={ [rehypeRaw] }
                    >
                        {footer_text}
                    </ReactMarkdown>
                    : undefined
            }

            {
                intro_text ? (
                    <strong className={ classnames(css.expander, { [css.hide]: !open }) } onClick={ handleClick }>
                        <Translation id="collapse" />
                    </strong>
                ) : undefined
            }
        </div>
    );
};

DynamicContent.propTypes = {
    category: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    selectedLocation: PropTypes.object.isRequired,
    sessionFeatures: PropTypes.arrayOf(PropTypes.string),
    track: PropTypes.func.isRequired
};

export { DynamicContent };

export default compose(
    withTrack,
    injectIntl,
    withSessionFeature
)(DynamicContent);
