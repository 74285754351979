import { getEMIChoices, getMaxLoanAmountLimit } from 'Helpers/financeConfig';
import { createSelector } from 'reselect';

export const computeMinHitchAmount = ({ maxLoanAmmount, maxLoanPercentage }, carValue, enableEmiFeature) => {
    if (!enableEmiFeature) {
        return NaN;
    }
    const maxLoanAmountLimit = getMaxLoanAmountLimit(carValue, maxLoanAmmount, maxLoanPercentage);

    return carValue - maxLoanAmountLimit;
};

export const computeMinEMIForHitchAmountWithPeriod = ({ interestRate, installments }, carValue, minHitchAmt, enableEmiFeature) => {
    if (!enableEmiFeature) {
        return { emi: NaN, repaymentPeriodCount: NaN };
    }
    const emiChoices = getEMIChoices(carValue - minHitchAmt, interestRate, installments);

    return emiChoices.length ? emiChoices[emiChoices.length - 1] : { emi: 0, repaymentPeriodCount: 0 };
};

export const computeMinEMIForMinHitchAmount = (config, carValue, enableEmiFeature) => {
    const minHitechAmt = computeMinHitchAmount(config, carValue, enableEmiFeature);

    return computeMinEMIForHitchAmountWithPeriod(config, carValue, minHitechAmt, enableEmiFeature).emi;
};

export const getMinimumHitchAmount = createSelector(
    state => state.financeConfig || {},
    (_, props) => props.carValue,
    (_, props) => props.enableEmiFeature,
    computeMinHitchAmount
);

export const getMinimumEmiForMinHitchAmountWithPeriod = createSelector(
    state => state.financeConfig || {},
    (_, props) => props.carValue || 0,
    getMinimumHitchAmount,
    (_, props) => props.enableEmiFeature,
    computeMinEMIForHitchAmountWithPeriod
);
