export const getBreadCrumbLinks = (links, intl) => {
    return links.map(({ translation, ...link }) => {
        const text = translation ? (
            intl.formatMessage(translation, translation.values)
        ) : link.text;

        return {
            ...link,
            text
        };
    });
};
