/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import VasTag from './../VasTag/VasTag';
import css from './VasTagsBottomInfo.APP_TARGET.scss';
import { BLACKBACKGROUND2, MONETIZATIONDARK1 } from 'Constants/colors';
import { setVasItem } from 'Actions/vasSheet';

export const VasTagsBottomInfo = ({
    itemId,
    vasConfigValues,
    gradient,
    setVasItem,
    onTrack,
    onVasTagTap,
    visualizationType
}) => {
    const vasTagContainerEl = useRef(null);
    const [hiddenTagsCount, setHiddenTagsCount] = useState(0);

    useEffect(() => {
        if (vasTagContainerEl.current) {
            const containerWidth = vasTagContainerEl.current.offsetWidth;
            const tagEls = vasTagContainerEl.current.querySelectorAll('[data-tag]');
            const tagElsLen = tagEls ? tagEls.length : 0;

            if (tagElsLen > 1) {
                // we want to calculate if we added more than one vas tag
                let filledWidth = tagEls[0].offsetWidth;
                let filledTagsCount = 1;

                for (let i = 1; i < tagElsLen; i++) {
                    ++filledTagsCount;
                    filledWidth += tagEls[i].offsetWidth;
                    if (filledWidth >= containerWidth) {
                        --filledTagsCount;
                        break;
                    }
                }

                setHiddenTagsCount(vasConfigValues.length - filledTagsCount);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTap = (e, chosen_option) => {
        e.stopPropagation();
        e.preventDefault();

        if (!chosen_option) {
            chosen_option = hiddenTagsCount === 0 ? 'i' : `+${hiddenTagsCount}`;
        }

        onTrack('vas_tag_clicked', {
            select_from: 'listing',
            chosen_option
        });
        setVasItem({
            selectedItemId: itemId,
            isFrmListing: true
        });
        onVasTagTap();
    };

    return (
        <div
            className={ css.bottomCardInfo }
            onClick={ handleTap }
            data-aut-id="vasStripBottom"
        >
            { !!vasConfigValues.length && <>
                <div className={ classNames(css.vasTagsContainer, css[gradient]) } ref={ vasTagContainerEl } >
                    {vasConfigValues.map((vasConfigVal, idx) => {
                        if (vasConfigVal) {
                            return (
                                <React.Fragment
                                    key={ `fr-${vasConfigVal.title}` }
                                >
                                    <VasTag
                                        vasImageUrl={ vasConfigVal.imageURI }
                                        vasTitle={ vasConfigVal.title }
                                        onVasTagTap={ handleTap }
                                        showSeparator={ idx !== 0 }
                                        visualizationType={ visualizationType }
                                    />
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </div>

                <div className={ classNames(css.moreInfo, { [css.infoIcn]: hiddenTagsCount === 0 }) } onClick={ onVasTagTap }>
                    {hiddenTagsCount === 0 ? 'i' : `+${hiddenTagsCount}`}
                </div>
            </>}
        </div>
    );
};

export const mapDispatchToProps = { setVasItem };

VasTagsBottomInfo.propTypes = {
    itemId: PropTypes.string.isRequired,
    vasConfigValues: PropTypes.array.isRequired,
    gradient: PropTypes.oneOf([BLACKBACKGROUND2, MONETIZATIONDARK1]),
    setVasItem: PropTypes.func.isRequired,
    onTrack: PropTypes.func,
    onVasTagTap: PropTypes.func,
    visualizationType: PropTypes.string
};

VasTagsBottomInfo.defaultProps = {
    gradient: BLACKBACKGROUND2,
    onTrack: () => {}
};

export default connect(null, mapDispatchToProps)(VasTagsBottomInfo);
