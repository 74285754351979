import { isEmpty } from 'Helpers/objects';
import { decodeRelevanceFilters } from 'Helpers/filters';
import { getNestedFilters } from 'Helpers/filtersTanak';

import { getURLParams } from 'Selectors/url';

import { urls, TYPE } from 'Reducers/dynamicContent';
import { getType } from 'Reducers/helpers/typeModifier';

function getDynamicContentPayload(state, query, params) {
    const categoryID = params.categoryID;
    const abundanceFilters = decodeRelevanceFilters(query?.filter);
    const filtersByAttr = state?.filters?.elements[categoryID]?.filtersInfoByAttr || undefined;

    let nestedFilters;

    if (filtersByAttr) {
        nestedFilters = getNestedFilters(abundanceFilters, filtersByAttr);
    }

    if (!abundanceFilters?.make && abundanceFilters?.model && nestedFilters?.make) {
        abundanceFilters.make = Object.keys(nestedFilters.make[0])[0];
    }

    const itemParams = {
        ...abundanceFilters,
        category: params?.categoryID,
        location: params?.geoID || state?.locations?.selectedLocation?.id,
        ...(isEmpty(nestedFilters) ? {} : { 'nested-filters': nestedFilters })
    };

    if (itemParams.make || itemParams.model) {
        itemParams.showAllCars = true;
    }

    const stringParams = getURLParams(state, itemParams);
    const url = stringParams ? urls.getDynamicContent(stringParams) : '';
    const type = getType(TYPE, url);

    return { type, url };
}

export function getDynamicContentUrl(state, query, params) {
    const payload = getDynamicContentPayload(state, query, params);

    return payload.url;
}

export function getDynamicContentType(state, query, params) {
    const payload = getDynamicContentPayload(state, query, params);

    return payload.type;
}
