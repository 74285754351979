import { createSelector } from 'reselect';

const getCanonical = createSelector(
    ({ canonicals: { elements }}) => elements,
    (state, { location }) => location.pathname,
    (elements, pathname) => {
        return elements[pathname];
    }
);

export {
    getCanonical
};
