import { TYPE, listingSliderUrl } from 'Reducers/listingSlider';
import { getSessionLong } from 'Helpers/userSession';
import * as urlHelper from 'Helpers/url';

import { getItemsParamsSelector } from 'Selectors/items';
import { getURLParams } from 'Selectors/url';

import { ITEMS_SEARCH_SOURCE } from 'Constants/items';
import { PLATFORM } from 'Constants/device.APP_TARGET';

import { sortRelevanceParams } from './items';

export const DEFAULT_SIZE = 5;

export const updateSliderCollectionUrl = (url, bundleType) => ({
    type: `UPDATE_COLLECTION_URL_${bundleType.toUpperCase()}_${TYPE}`,
    url
});

export const getListingSliderData = ({
    cookies,
    location,
    size = DEFAULT_SIZE,
    bundleType
}) => (dispatch, getState, { get }) => {
    const state = getState();
    const onApCookie = cookies && (cookies.onap || cookies.onap_dev);
    const sessionId = onApCookie && getSessionLong(onApCookie);
    const { location: locationUrl, category, search } = urlHelper.buildObjectFromURL(location.pathname);
    const params = {
        geoID: locationUrl && locationUrl.id,
        categoryID: category && category.id,
        text: search,
        isSearchCall: location.query && location.query.isSearchCall
    };

    const CLIENT_ID_HEADER = `web-${PLATFORM}`;
    const { location: locationId, ...restParams } = getItemsParamsSelector(ITEMS_SEARCH_SOURCE)(state, { location, params, spellCheck: false });
    const itemsParams = {
        sessionId,
        platform: CLIENT_ID_HEADER,
        locationId,
        size,
        ...restParams
    };
    const requestHeader = { 'x-panamera-client-id': CLIENT_ID_HEADER };

    const sortedRelevanceParams = sortRelevanceParams(itemsParams);
    const stringParams = getURLParams(state, sortedRelevanceParams);
    const url = stringParams ? `${listingSliderUrl}?${stringParams}` : listingSliderUrl;

    const { listingSlider } = state;
    const isFetching = listingSlider && listingSlider[bundleType] && listingSlider[bundleType].isFetching[url];
    const hasFailed = listingSlider && listingSlider[bundleType] && listingSlider[bundleType].isError[url];
    const hasData = listingSlider && listingSlider[bundleType] && listingSlider[bundleType].collections && listingSlider[bundleType].collections[url];

    if (!isFetching && !hasData && !hasFailed) {
        dispatch(updateSliderCollectionUrl(url, bundleType));
        return dispatch(get(url, `${bundleType.toUpperCase()}_${TYPE}`, null, requestHeader));
    }

    const lastCollectionUrl = listingSlider && listingSlider[bundleType] && listingSlider[bundleType].lastCollectionUrl;

    // if the last items collection which was fetched isnt same as current url
    if (lastCollectionUrl !== url) {
        // extract the params from the lastCollectionUrl
        // and compare it with the current url being fetched
        const stripParams = (lastCollectionUrl.indexOf('?') === -1)
            ? {}
            : urlHelper.queryStringToParams(lastCollectionUrl.slice(lastCollectionUrl.indexOf('?') + 1));
        const { cursor, page, suggestedPage, ...res } = stripParams; // eslint-disable-line
        const lastCollectionStringParams = urlHelper.paramsToQueryString(sortRelevanceParams(res));
        const areParamsEquals = urlHelper.compareEncodedParams(stringParams, lastCollectionStringParams);

        // if the params are not equal
        // we will clear the collection stored in our state
        if (!areParamsEquals) {
            const newUrlWithParams = (stringParams.length > 0) ? `${listingSliderUrl}?${stringParams}` : listingSliderUrl;

            dispatch(updateSliderCollectionUrl(newUrlWithParams, bundleType));
        }
    }

    return Promise.resolve();
};
