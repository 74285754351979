import { SLIDER_TYPE } from 'Constants/listingSlider';
import { isMobile } from './devices';

export const getListingSliderType = (marketConfig, category) => {
    const carsCategory = marketConfig.get('olxAutos', 'carCategory');
    const showOlxAutosCarsSlider = marketConfig.get('showOlxAutosCarsSlider');
    const showInspectedCarsSlider = marketConfig.get('showInspectedCarsSlider');

    const isCarCategory = category?.id === carsCategory;

    if (!isMobile || !isCarCategory) {
        return false;
    }

    if (showOlxAutosCarsSlider) {
        return SLIDER_TYPE.OLX_AUTOS;
    }

    if (showInspectedCarsSlider) {
        return SLIDER_TYPE.INSPECTION;
    }

    return false;
};
