import React from 'react';
import PropTypes from 'prop-types';
import withConfig from 'HOCs/withConfig/withConfig';
import { urlJoin } from 'Helpers/url';

export const Structured = ({ links, config }) => {
    if (!links.length) {
        return null;
    }
    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={ {
                __html: JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    itemListElement: links.map(({ text = '', href = '/' }, i) => ({
                        '@type': 'ListItem',
                        position: i + 1,
                        item: {
                            '@id': urlJoin(config.get('host'), href),
                            name: text.trim()
                        }
                    }))
                })
            } }
        />
    );
};

Structured.propTypes = {
    links: PropTypes.array,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired
};

Structured.defaultProps = {
    links: []
};

export default withConfig(Structured);
