/* eslint-disable no-console */
import { DependencyManager } from 'olx-autos-landing-page';
import { exponeaTrack } from 'Helpers/exponeaTrack';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import ItemsCarousel from './components/ItemsCarousel/ItemsCarousel';
import { buildURL } from 'Helpers/url';
import { getBundlesParams } from 'Actions/bundles';
import { getQueryRedirectionParams } from 'Selectors/olxAutos';

class OLXAutosWrapper {
    constructor() {
        const dependencies = {
            constants: {
                platform: PLATFORM
            },
            components: {
                ItemsCarousel
            },
            functions: {
                exponeaTrack,
                buildURL,
                getBundlesParams,
                getQueryRedirectionParams
            }
        };

        this.DM = DependencyManager.init(dependencies);
    }
}

export default OLXAutosWrapper;
