import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import { Link } from 'react-router-dom';
import withRouter from 'HOCs/withRouter';
import { withFeatureFlag as withLQFeatureFlag } from 'LaquesisEOL/withFeatureFlag';
import { useExperiment } from 'LaquesisEOL/useExperiment';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import ItemImage from 'Components/Listing/components/ItemImage';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import VasTagsBottomInfo from '../VasTagsBottomInfo/VasTagsBottomInfo';
import ItemFavouriteIcon from '../../../ItemFavouriteIcon/ItemFavouriteIcon';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import useItemInfo from 'Hooks/useItemInfo';
import { getVasConfigValues, getLocale } from 'Selectors/config';
import { computeMinEMIForMinHitchAmount } from 'Selectors/financeConfig';
import { isMobile } from 'Helpers/devices';
import { isCocoFofoUser, getIsSponsoredAd } from 'Helpers/item';
import { getFormattedAmount } from 'Helpers/numbers';
import { EMI_EXPERIMENT, ITEM_SOURCE, LQ_SHORTLIST_FEATURE } from 'Constants/items';
import { LISTING_TYPES, trustTagHash, DEFAULT_SIZE_BY_VISUALIZATION } from 'Constants/listing';
import { BLACKBACKGROUND2, MONETIZATIONDARK1 } from 'Constants/colors';
import { IS_DESKTOP, IS_MOBILE } from 'Constants/device.APP_TARGET';

import { THEMES } from 'Constants/bundles';
import css from './ItemCard.APP_TARGET.scss';
import SpinViewTag from 'Components/Listing/components/SpinViewTag';
import CompareIconCTA from 'Components/Compare/CompareIconCTA/CompareIconCTA';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { CAR_COMPARE_FLOW_STEP } from 'Constants/tracking';
import { getVisibleCampaignTags } from 'Selectors/visibleCampaign';
import { USER_TYPE } from 'Constants/users';
import { setSelectFrom } from 'Actions/track';
import { getSelectFromFilter } from 'Helpers/tracking';
import { getItemWithLocation } from 'Helpers/item';
import { getStatus } from '../ItemCardStatusMX.utils';
import { convertKeysToSnakeCase } from 'Helpers/objects';
import { getUserInfoByCategory } from 'Helpers/users';

export const ItemCard = ({
    userType,
    chosenOption,
    className,
    config,
    enabledRealImpressions,
    friendsInCommon,
    isAdSuggested,
    item,
    listingType,
    location,
    onItemClick,
    onView,
    source,
    tag,
    trackerOrigins,
    visualizationType,
    vasConfigValues,
    financeConfig,
    currentLocale,
    onVasTagTap,
    openComparePopup,
    track,
    showOriginalTitle,
    showSubTitle,
    showSubDetails,
    showFullPrice,
    showOlxAutosAdCardTag,
    selectFrom,
    showMakeModel,
    showEmiPlaceHolder,
    showVasStrip,
    imageHeight,
    imageWidth,
    isListingPage,
    featureFlag,
    visibleCampaignTagsInfo,
    zrpWidgetType,
    isNewUspVasDesign,
    listingBodyType,
    setSelectFromTrack,
    categories,
    showSponseredTag = false,
    showDealerTag,
    categoryId
}) => {
    const {
        category_id,
        displayTitle,
        displayDetails,
        id,
        image,
        isFeatured,
        isInspected,
        price,
        rawPriceValue,
        priceCurrency,
        scoreFactor,
        slug,
        spell_id,
        spell_key,
        spell_v,
        title,
        user_id,
        user_type,
        subtitle,
        subDetails,
        make,
        model,
        isSpinViewAvailable,
        visibleCampaignTagIds
    } = useItemInfo(getItemWithLocation(item, config), categories);
    const emiExperimentVariant = useExperiment(EMI_EXPERIMENT[config.get('siteCode')] || '');
    const showEmiHighlighted = emiExperimentVariant === 'b';
    const showFeaturedTag = config.get('adpvAuto', 'featuredTag');
    const enableEmiFeature = config.get('enableEmiFeature');
    const hideVasTagListing = config.get('hideVasTagListing');
    const isCarCompareEnabled = config.get('olxAutos', 'isCarCompareEnabled');
    const showFavBtn = featureFlag === LQ_SHORTLIST_FEATURE;
    const showCompareIcon = isListingPage && isCarCompareEnabled;
    const trackselectFrom = isListingPage ? trackerOrigins.LISTING : '';
    const theme = config.get('theme', 'id');
    const smallSizeFavIcon = 14;
    const bigSizeFavIcon = 16;
    const campaignTagLabels = visibleCampaignTagIds.filter(tag => !!visibleCampaignTagsInfo[tag]?.labelName)
        .map(tag => {
            return { labelId: tag, ...visibleCampaignTagsInfo[tag] };
        });

    const staticAssetUrl = config.get('staticAssets');
    const isSponsored = getIsSponsoredAd(item) && showSponseredTag;
    const status = getStatus({ showFeaturedTag, isFeatured, tags: item?.tags || convertKeysToSnakeCase(item?.monetizationInfo?.tags), css: css.status, isSponsored, staticAssetUrl });

    const linkToProp = useMemo(() => {
        const state = { isAdSuggested, source, select_from: selectFrom };

        // Add fields for tracking related ads
        if (source === ITEM_SOURCE.RELATED) {
            state.relatedAdTracking = {
                impressions: [{
                    adId: id,
                    scoreFactor: typeof scoreFactor === 'undefined' ? 'null' : scoreFactor
                }],
                chosen_option: typeof chosenOption === 'undefined' ? 'null' : chosenOption,
                origin: trackerOrigins.ITEM_PAGE
            };
        }

        // Add tracking for featured ad
        state.featuredAdTracking = { chosen_option: typeof chosenOption === 'undefined' ? 'null' : chosenOption };

        if (location && location.state && location.state.fromBundleResultset) {
            state.fromBundleResultset = location.state.fromBundleResultset;
        }

        if (zrpWidgetType) {
            state.select_from = `rf_${zrpWidgetType}`;
        }

        if (listingBodyType) {
            state.select_from = `rf_${listingBodyType}_listing`;
        }

        return {
            pathname: slug,
            state
        };
    }, [id, slug, scoreFactor, isAdSuggested, source, chosenOption, trackerOrigins, location, selectFrom, listingBodyType, zrpWidgetType]);

    const emiTranslatedText = useMemo(() => {
        const minEmi = computeMinEMIForMinHitchAmount(financeConfig, rawPriceValue, enableEmiFeature);
        const formattedEmiText = getFormattedAmount(minEmi, priceCurrency, currentLocale);

        const values = {
            amt: ''
        };

        return (
            <Translation id="emiFullText" values={ values } >
                { val => (
                    <span className={ css.emi }>
                        { val }{showEmiHighlighted ? <b className={ css.emi__value }> {formattedEmiText}</b> : formattedEmiText }
                    </span>
                )}
            </Translation>
        );
    }, [currentLocale, financeConfig, priceCurrency, rawPriceValue, enableEmiFeature, showEmiHighlighted]);

    const onViewChange = useCallback(inView => {
        if (!enabledRealImpressions) {
            return;
        }
        if (inView) {
            onView({
                id,
                title,
                user_id,
                isFeatured,
                spell_id,
                spell_key,
                spell_v,
                isInspected
            });
        }
    }, [onView, id, title, user_id, isFeatured, spell_id, spell_key, spell_v, isInspected, enabledRealImpressions]);

    const onClick = useCallback(() => {
        const selectFromFilter = getSelectFromFilter(listingBodyType);

        setSelectFromTrack(selectFromFilter);
        onItemClick(item);
    }, [item, onItemClick, listingBodyType, setSelectFromTrack]);

    const campaignTags = campaignTagLabels.length
        ? campaignTagLabels.map(label => (
            <span
                data-aut-id={ label.labelId }
                className={ cx(css.campaignTag, { [css.listingCard]: isListingPage }) }
                style={ { backgroundColor: label.labelColor, color: label.labelTextColor } }>
                { label.labelName }
            </span>)
        )
        : null;

    const dealerTag = showDealerTag && item?.dealer_showroom_enabled && item?.dealer_logo_url && <img
        alt="dealer_logo"
        src={ item?.dealer_logo_url }
        className={ cx(css.olxAutosTag, css.dealerTag) }
    />;

    const onComparePopupOpen = () => {
        setSessionStorageItem('compare_flow_step', CAR_COMPARE_FLOW_STEP.LISTING);
        openComparePopup();
    };

    return (
        <ReactObserver
            tag={ tag }
            data-aut-id="itemBox1"
            data-aut-category-id={ category_id }
            onChange={ onViewChange }
            className={ cx(css[`${visualizationType}ItemCard`], css[`${visualizationType}ItemCardContent`], className, { [css.withoutVas]: !showVasStrip || hideVasTagListing, [css.hideVasTag]: hideVasTagListing || isNewUspVasDesign }) }>
            <Link
                to={ linkToProp }
                state={ linkToProp?.state }
                className={ cx({
                    [css.withStatusTagRow]: IS_MOBILE && !status && !campaignTags
                }) }
                onClick={ onClick } >
                <div className={ css.topContainer }>
                    {isSpinViewAvailable && <SpinViewTag isFeaturedTagVisible={ showFeaturedTag && isFeatured } />}
                    {image
                        && <ItemImage
                            image={ image }
                            friendsInCommon={ friendsInCommon }
                            defaultSize={ DEFAULT_SIZE_BY_VISUALIZATION[visualizationType] }
                            alt={ title }
                            visualizationType={ visualizationType }
                            listingType={ listingType }
                            height={ imageHeight }
                            width={ imageWidth }
                        />
                    }
                    {dealerTag ? <span className={ css.dealerContainer }>
                        {dealerTag}
                    </span> : null}
                    {IS_DESKTOP
                        && showOlxAutosAdCardTag && <div className={ cx(css.statusTags, { [css.listingCard]: isListingPage }) }>
                        {status}
                        {campaignTags}
                    </div>
                    }
                    {
                        listingType === LISTING_TYPES.ITEMS && (
                            <div className={ cx(css.resume, { [css.bp0]: showSubDetails, [css.withCarCompareIcon]: showCompareIcon }) }>
                                {IS_MOBILE
                                    && showOlxAutosAdCardTag && <div className={ css.statusTags }>
                                    {status}
                                    {campaignTags}
                                </div>
                                }
                                {price && (
                                    <div className={ cx(css.priceDetails, { [css.highlightEmi]: showEmiHighlighted }) } data-aut-id="itemPrice">
                                        <span className={ cx(css.price, { [css.fullWidth]: showFullPrice }) } >
                                            { showEmiHighlighted ? <Translation id="carValueLabel">{ val => `${val} ${price}` }</Translation> : price}
                                        </span>
                                        { !(theme === THEMES.OTOPLUS) && enableEmiFeature ? emiTranslatedText : !(theme === THEMES.OTOPLUS) && showEmiPlaceHolder && <div className={ css.emiPlaceHolder } /> }
                                    </div>
                                )}
                                {/* variant #1: show display title created */}
                                {!showOriginalTitle && displayTitle && (
                                    <h3 className={ css.title } title={ displayTitle } data-aut-id="itemTitle">{displayTitle}</h3>
                                )}
                                {/* variant #2: show original title in api response */}
                                {showOriginalTitle && !showMakeModel && <div className={ css.originalTitle } title={ title }>{title}</div>}

                                {/* variant #1: show displayDetails i.e fuel,km_driven,itemLocation */}
                                {!showSubTitle && displayDetails && <div className={ css.details } data-aut-id="itemDetails">{
                                    displayDetails.map((detail, index) => (
                                        <React.Fragment>
                                            {index !== 0 && <span className={ css.detSep }>|</span>}
                                            {detail}
                                        </React.Fragment>)
                                    )
                                }</div>}
                                {/* variant #2: show subtitle ie. year-km_driven */}
                                {showSubTitle && <div className={ css.subtitle }>
                                    {subtitle}
                                </div>}

                                {showMakeModel && make && model
                                    && <div
                                        className={ css.title }
                                        title={ title }
                                        data-aut-id="itemTitle"
                                    >
                                        {`${make} ${model}`}
                                    </div>}

                                {
                                    showSubDetails && (
                                        <div className={ css.subDetailsContainer }>
                                            {subDetails?.itemLocation && (
                                                <div className={ css.itemLocation }>
                                                    <Icon icon="location" size={ 16 } />
                                                    <span className={ css.locationText }>
                                                        {subDetails.itemLocation}
                                                    </span>
                                                </div>
                                            )}
                                            {subDetails?.displayDate && <span className={ css.displayDate }>{subDetails.displayDate}</span>}
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }
                </div>
                { listingType === LISTING_TYPES.ITEMS && !hideVasTagListing && showVasStrip && !isNewUspVasDesign && (
                    <VasTagsBottomInfo
                        className={ css.bottomCardInfo }
                        itemId={ id }
                        vasConfigValues={ vasConfigValues }
                        gradient={ isCocoFofoUser((user_type || userType), categoryId) ? MONETIZATIONDARK1 : BLACKBACKGROUND2 }
                        onVasTagTap={ onVasTagTap }
                        onTrack={ track }
                        visualizationType={ visualizationType }
                    />
                )}
            </Link>

            {
                showFavBtn
                && listingType === LISTING_TYPES.ITEMS
                && <ItemFavouriteIcon
                    item={ item }
                    className={ css.favIcon }
                    btnClassName={ css.favBtn }
                    size={ isMobile ? smallSizeFavIcon : bigSizeFavIcon }
                    color="white"
                    selectFrom={ trackselectFrom }
                />
            }
            {IS_MOBILE && (item.video_status
                    && <div className={ css.videoOverMobile }>
                        <IconWrapper className={ css.videoIconMobile } icon="play" size={ 12 } color="white" />
                        <span className={ css.videoIconMobile }>Video</span>
                    </div>)}

            {
                showCompareIcon && (
                    <CompareIconCTA
                        itemId={ id }
                        className={ cx(css.compareIconCTA, { [css.hideVasTag]: hideVasTagListing || isNewUspVasDesign }) }
                        // eslint-disable-next-line react/jsx-no-bind
                        openComparePopup={ onComparePopupOpen }
                        isListingPage
                        isFirstItemCard={ chosenOption === 0 }
                    />
                )
            }
        </ReactObserver>
    );
};

ItemCard.propTypes = {
    userType: PropTypes.string,
    config: PropTypes.object.isRequired,
    item: PropTypes.object,
    onView: PropTypes.func,
    className: PropTypes.string,
    friendsInCommon: PropTypes.bool,
    trackerOrigins: PropTypes.object,
    enabledRealImpressions: PropTypes.bool,
    visualizationType: PropTypes.oneOf([
        'grid', 'big', 'list', 'smallGrid'
    ]),
    listingType: PropTypes.string,
    onItemClick: PropTypes.func,
    tag: PropTypes.string,
    isAdSuggested: PropTypes.bool,
    source: PropTypes.string,
    // Added for tracking which ad clicked
    chosenOption: PropTypes.number,
    location: PropTypes.object,
    vasConfigValues: PropTypes.array,
    financeConfig: PropTypes.object,
    currentLocale: PropTypes.string,
    onVasTagTap: PropTypes.func,
    openComparePopup: PropTypes.func,
    track: PropTypes.func.isRequired,
    showOriginalTitle: PropTypes.bool,
    showSubTitle: PropTypes.bool,
    showSubDetails: PropTypes.bool,
    showFullPrice: PropTypes.bool,
    showOlxAutosAdCardTag: PropTypes.bool,
    showMakeModel: PropTypes.bool,
    showEmiPlaceHolder: PropTypes.bool,
    showVasStrip: PropTypes.bool,
    selectFrom: PropTypes.string,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    isListingPage: PropTypes.bool,
    isNewUspVasDesign: PropTypes.bool,
    featureFlag: PropTypes.string,
    zrpWidgetType: PropTypes.string,
    visibleCampaignTagsInfo: PropTypes.object,
    listingBodyType: PropTypes.string,
    setSelectFromTrack: PropTypes.func,
    categories: PropTypes.object,
    showSponseredTag: PropTypes.bool,
    showDealerTag: PropTypes.bool,
    categoryId: PropTypes.string
};

ItemCard.defaultProps = {
    userType: '',
    item: {},
    className: '',
    onView: () => { },
    friendsInCommon: false,
    visualizationType: 'grid',
    enabledRealImpressions: false,
    listingType: LISTING_TYPES.ITEMS,
    onItemClick: () => { },
    tag: 'li',
    isAdSuggested: false,
    chosenOption: null,
    trackerOrigins: {},
    vasConfigValues: [],
    financeConfig: {},
    currentLocale: '',
    onVasTagTap: () => {},
    openComparePopup: () => {},
    showOriginalTitle: false,
    showSubTitle: false,
    showSubDetails: false,
    showFullPrice: false,
    showOlxAutosAdCardTag: true,
    showMakeModel: false,
    showEmiPlaceHolder: true,
    showVasStrip: true,
    isListingPage: false,
    featureFlag: '',
    zrpWidgetType: '',
    visibleCampaignTagsInfo: {},
    listingBodyType: '',
    setSelectFromTrack: () => {},
    categories: {},
    showSponseredTag: false,
    showDealerTag: false
};

export const mapStateToProps = (state, ownProps) => {
    const user = state.users.elements[ownProps.item.user_id];
    const userVasTags = user && user.additional_services || [];
    const { categoryId } = ownProps;
    const dealer = getUserInfoByCategory(user, categoryId);

    return {
        userType: dealer && dealer.dealer_type.toLowerCase() || USER_TYPE.REGULAR,
        currentLocale: getLocale(state),
        trackOrigin: state.track.origin,
        selectFrom: ownProps.selectFrom || (ownProps.location.state && ownProps.location.state.source),
        vasConfigValues: getVasConfigValues(state, ownProps.item.vasTags || userVasTags || []),
        financeConfig: state.financeConfig || {},
        visibleCampaignTagsInfo: getVisibleCampaignTags(state),
        categories: state.categories
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setSelectFromTrack: origin => dispatch(setSelectFrom(origin))
    };
};

export default compose(
    withConfig,
    withTrack,
    withRouter,
    withBackButtonHandling(trustTagHash),
    withLQFeatureFlag(LQ_SHORTLIST_FEATURE),
    connect(mapStateToProps, mapDispatchToProps)
)(ItemCard);
