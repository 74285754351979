import React from 'react';
import PropTypes from 'prop-types';
import css from './Notification.scss';
import { css as uiCss } from 'panamera-react-ui';

import cx from 'classnames';

const { icons } = uiCss;

function validType(props, propName, componentName) {
    const validTypes = ['info', 'warning', 'success'];

    if (props[propName]) {
        const value = props[propName];

        if (typeof value === 'string' && validTypes.indexOf(value) !== -1) {
            return null;
        }

        return new Error(`${propName} ${value} in ${componentName} is not a valid type => [${validTypes}]`);
    }

    return null;
}

class Notification extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func,
        onClick: PropTypes.func,
        isActionable: PropTypes.bool,
        showCloseBtn: PropTypes.bool,
        type: validType,
        style: PropTypes.object,
        className: PropTypes.string,
        updateNoticationMessage: PropTypes.bool
    };

    static defaultProps = {
        children: '',
        onClick: () => {},
        onClose: () => {},
        isActionable: false,
        showCloseBtn: false,
        type: 'info',
        style: {},
        className: ''
    };

    constructor() {
        super();

        this.state = {
            close: false
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { updateNoticationMessage } = nextProps;

        if (this.state.close !== nextState.close || updateNoticationMessage) {
            return true;
        }

        return false;
    }

    onClose = event => {
        event.stopPropagation();
        this.setState({
            close: true
        });
        this.props.onClose();
    };

    render() {
        return (
            <div
                className={ cx(
                    css.notification,
                    this.props.className,
                    css[this.props.type],
                    {
                        [css.isActionable]: this.props.isActionable,
                        [css.close]: this.state.close
                    }
                ) }
                onClick={ this.props.onClick }
                data-aut-id="onClick"
                style={ this.props.style }
            >
                { this.props.children }
                { this.props.showCloseBtn
                    && <span
                        className={ cx(
                            css.close,
                            icons.panameraIcons,
                            icons['icon-Cross']
                        ) }
                        onClick={ this.onClose }
                        data-aut-id="onClose"
                    />
                }
            </div>
        );
    }
}

export default Notification;
