import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import withConfig from 'HOCs/withConfig/withConfig';
import { FormattedMessage as Translation } from 'react-intl';
import { decodeSearch, camelize } from 'Helpers/strings';
import { parsePathname } from 'Helpers/listing';
import { getCategoryHeirarchy } from 'Helpers/categories';
import { itemsMetadataSelector } from 'Selectors/items';

export const Title = ({ params: { text, categoryID }, flatCategoryMap, location, config, appliedFilters }) => {
    const isCarsAndBakkies = `${categoryID}` === `${config.get('SEO', 'showMakeModelForCarCategory')}`;
    const parsedLocation = parsePathname(location.pathname);
    // XXX: This was copied from previous implementation. The flag `isCarsAndBakkies` is only turn on in English speaking
    //      languages. This dirty hack will be removed when the SEO service (aka: Mystique) is implemented.
    const addLocation = isCarsAndBakkies && parsedLocation.name ? _text => `${_text} in ${parsedLocation.name}` : t => t;
    const modelFilter = appliedFilters && appliedFilters.find(appliedFilter => appliedFilter.id === 'model');
    const decodedText = decodeSearch(text);

    if (decodedText) {
        return addLocation(camelize(decodedText));
    }

    if (modelFilter && modelFilter.value) {
        return addLocation(modelFilter.value);
    }

    const makeFilter = appliedFilters && appliedFilters.find(appliedFilter => appliedFilter.id === 'make');

    if (makeFilter && makeFilter.value) {
        return addLocation(makeFilter.value);
    }

    const categoryHeirarchy = getCategoryHeirarchy(flatCategoryMap, categoryID, parsedLocation);
    const lastCategory = categoryHeirarchy && categoryHeirarchy.pop();

    if (lastCategory) {
        return addLocation(lastCategory.text);
    }

    return <Translation id="allResults" />;
};

Title.propTypes = {
    params: PropTypes.object.isRequired,
    flatCategoryMap: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    appliedFilters: PropTypes.array
};

const mapStateToProps = state => ({
    flatCategoryMap: state.categories.elements,
    appliedFilters: (itemsMetadataSelector(state) || {}).appliedFilters
});

export default compose(
    withConfig,
    withRouter,
    connect(mapStateToProps)
)(Title);
