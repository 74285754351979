import React, { useMemo, useCallback } from 'react';
import { Link } from 'panamera-react-ui';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parsePathname } from 'Helpers/listing';
import { buildURL } from 'Helpers/url';
import withTrack from 'HOCs/withTrack/withTrack';
import { CLICK_DID_YOU_MEAN_HINT } from 'Constants/tracking';
import css from './SearchHint.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';

export const SearchHint = ({
    hintTerm,
    hintText,
    location,
    track
}) => {
    const startIdx = hintText.indexOf('$');
    const endIdx = hintText.indexOf('}');

    const linkUrl = useMemo(() => {
        const urlParams = {
            location: parsePathname(location.pathname),
            search: hintTerm
        };

        urlParams.params = { isSearchCall: true };

        return buildURL(urlParams);
    }, [location, hintTerm]);

    const sendTrackInfo = useCallback(() => {
        track(CLICK_DID_YOU_MEAN_HINT, {
            search_string: hintTerm
        });
    }, [hintTerm, track]);

    return (
        <p className={ css.searchHint }>
            {hintText.slice(0, startIdx)}
            <React.Fragment key={ `search-hint-${hintTerm}` }>
                <span>
                    &ldquo;
                    <Link
                        to={ linkUrl }
                        className={ classNames(css.link) }
                        activeClassName={ css.active }
                        onClick={ sendTrackInfo }
                    >
                        {hintTerm}
                    </Link>
                &rdquo;
                </span>
            </React.Fragment>
            {hintText.slice(endIdx + 1)}
        </p>
    );
};

SearchHint.propTypes = {
    hintText: PropTypes.string.isRequired,
    hintTerm: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    track: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    category: state.categories.elements[props.categoryId]
});

export default compose(
    connect(mapStateToProps),
    withTrack
)(React.memo(SearchHint));
