import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import { isMobile } from 'Helpers/devices';

import css from './PaginationV2.scss';
import { paramsToQueryString } from 'Helpers/url';

export const DEFAULT_PAGE_SIZE = 40;
const DOTS = 'DOTS';
const NOOP = () => {};

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

class PaginationV2 extends Component {
    static defaultProps = {
        'data-aut-id': 'pagination',
        pageSize: DEFAULT_PAGE_SIZE,
        pageNeighbours: 2,
        pageOffset: 4,
        total: null,
        currentPage: 1,
        location: {
            query: {},
            pathname: '',
            search: ''
        },
        maxPage: 9
    }

    constructor(props) {
        super(props);
        const { total, pageSize, currentPage, maxItems } = props;
        const limit = maxItems ? Math.min(total, maxItems) : total;

        this.pageSize = typeof pageSize === 'number' ? pageSize : DEFAULT_PAGE_SIZE;
        this.totalRecords = typeof total === 'number' ? limit : 0;
        this.totalPages = Math.ceil(this.totalRecords / this.pageSize);

        this.state = { currentPage };
    }

    gotoPage = page => {
        const { onPageChanged = f => f } = this.props;

        const currentPage = Math.max(0, Math.min(page, this.totalPages));

        const paginationData = {
            currentPage,
            totalPages: this.totalPages,
            pageSize: this.pageSize,
            totalRecords: this.totalRecords
        };

        this.setState({ currentPage }, () => onPageChanged(paginationData));
    }

    handleClick = page => evt => {
        evt.preventDefault();
        this.gotoPage(page);
    }

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - 1);
    }

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + 1);
    }

    // will remove it when removing the feature flag.
    getPagesOld = () => {
        const { totalPages } = this;
        const { currentPage } = this.state;
        const { pageNeighbours, pageOffset } = this.props;

        // eslint-disable-next-line no-magic-numbers
        const totalBlocks = (pageNeighbours * 2) + 3;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages = range(startPage, endPage);
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;

            if (hasLeftSpill && !hasRightSpill) {
                const extraPages = range(startPage - pageOffset + 2, startPage - 1);

                pages = [DOTS, ...extraPages, ...pages];
            }
            else if (!hasLeftSpill && hasRightSpill) {
                const extraPages = range(endPage + 1, endPage + pageOffset);

                pages = [...pages, ...extraPages, DOTS];
            }
            else if (hasLeftSpill && hasRightSpill) {
                pages = [DOTS, ...pages, DOTS];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }

    getPages = () => {
        const { totalPages } = this;
        const { currentPage } = this.state;
        const { pageNeighbours, pageOffset } = this.props;

        const pageOff = pageOffset - 1;
        const totalBlocks = (pageNeighbours * 2) + pageOff;
        const totalBoxes = 9;

        if (totalPages <= totalBoxes) {
            return range(1, totalPages);
        }

        if (currentPage <= (pageNeighbours + pageOff)) {
            return [...range(1, totalBlocks), 'DOTS', totalPages];
        }
        else if (currentPage > (pageNeighbours + pageOff) && (totalPages - currentPage) >= (pageNeighbours + pageOff)) {
            return [1, 'DOTS', ...range(currentPage - pageNeighbours, currentPage + pageNeighbours), 'DOTS', totalPages];
        }
        else if ((totalPages - totalBlocks - pageNeighbours) <= currentPage - pageNeighbours) {
            return [1, 'DOTS', ...range(totalPages - (totalBlocks - 1), totalPages)];
        }

        return [];
    }

    render() {
        if (!this.totalRecords || this.totalPages === 1) {
            return null;
        }

        const { 'data-aut-id': dataAutId, location, pageOffset, pageNeighbours, maxPage } = this.props;
        const { query, pathname } = location;
        const { currentPage } = this.state;
        const pages = this.getPages();
        const firstPage = pages?.[0];
        const lastPage = pages?.[pages.length - 1];
        const backHref = currentPage === firstPage ? '' : `${pathname}?${paramsToQueryString({ ...query, page: currentPage - 1 })}`;
        const nextHref = currentPage === lastPage ? '' : `${pathname}?${paramsToQueryString({ ...query, page: currentPage + 1 })}`;

        return (
            <Fragment>
                <nav data-aut-id={ dataAutId } className={ css.nav }>
                    <ul className={ css.pagination }>
                        <li className={ css.arrowLeft } key="leftArrow">
                            <a
                                data-aut-id="arrowLeft"
                                icon="arrowLeft"
                                onClick={ backHref === '' ? NOOP : this.handleMoveLeft }
                                disable={ currentPage === firstPage }
                                title="Previous"
                                href={ backHref }
                                className={ classNames(css.iconButton, { [css.disableLink]: currentPage === firstPage }) }
                            >
                                <Icon icon="arrowLeft" size={ 16 } color={ currentPage === firstPage ? 'primaryLight2' : 'primary' } />
                                <Translation id="pagination_previous" />
                            </a>
                        </li>
                        { isMobile ? (
                            <span className={ css.pageText }>{ currentPage } / { lastPage }</span>
                        ) : pages?.map((page, index) => {
                            if (page === DOTS) {
                                return (
                                    <li className={ css.paginationItem } key={ `dots-${index}` }>
                                        <div className={ css.pageDots } data-aut-id="pageDots">
                                            <span className={ css.dots }>...</span>
                                        </div>
                                    </li>
                                );
                            }

                            const href = `${pathname}?${paramsToQueryString({ ...query, page })}`;

                            return (
                                <li className={ css.paginationItem } key={ index }>
                                    <a
                                        data-aut-id="pageItem"
                                        key={ index }
                                        disable={ currentPage < lastPage - (pageOffset + pageNeighbours) }
                                        selected={ currentPage === page }
                                        onClick={ this.handleClick(page) }
                                        href={ href }
                                        className={ classNames(
                                            css.pageItem,
                                            {
                                                [css.selected]: currentPage === page,
                                                [css.disableLink]: lastPage > maxPage && currentPage < (lastPage - pageOffset) && page === lastPage
                                            }
                                        ) }

                                    >{ page }</a>
                                </li>
                            );
                        }) }
                        <li className={ css.arrowRight } key="rightArrow" >
                            <a
                                data-aut-id="arrowRight"
                                icon="arrowRight"
                                disable={ currentPage === lastPage }
                                onClick={ nextHref === '' ? NOOP : this.handleMoveRight }
                                title="Next"
                                href={ nextHref }
                                className={ classNames(css.iconButton, { [css.disableLink]: currentPage === lastPage }) }
                            >
                                <Translation id="pagination_next" />
                                <Icon icon="arrowRight" size={ 16 } color={ currentPage === lastPage ? 'primaryLight2' : 'primary' } />
                            </a>
                        </li>
                    </ul>
                </nav>
            </Fragment>
        );
    }
}

export default compose(injectIntl)(PaginationV2);

PaginationV2.propTypes = {
    'data-aut-id': PropTypes.string,
    /** Total pages */
    total: PropTypes.number,
    pageSize: PropTypes.number,
    /** Cant pages displayed around selected page. ex [1 ... {4,5} 6 {7,8} ... 20] */
    pageNeighbours: PropTypes.number,
    /** Offset defines how many pages displays from the start/end before/after the dots. Ex. [1, { 2, 3 }, ..., 10] */
    pageOffset: PropTypes.number,
    onPageChanged: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    location: PropTypes.object.isRequired,
    maxItems: PropTypes.number,
    maxPage: PropTypes.number
};
