import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import { Link, Label } from 'panamera-react-ui';
import Button from 'Components/Button/Button';
import { LayoutHelper } from 'olx-autos-landing-page';
import classNames from 'classnames';

import { clearFiltersPreferences as clearFiltersPreferencesActionCreator } from 'Actions/filtersPreferences';
import {
    clearFilterRecencyOrder as clearFilterRecencyOrderActionCreator,
    addFiltersRecencyOrder as addFiltersRecencyOrderActionCreator
} from 'Actions/filters';

import {
    getParsedAppliedFilters,
    getAppliedFiltersLabels,
    getCurrentSorting,
    getHiddenFilterIds
} from 'Selectors/filtersTanak';
import { categorySelector } from 'Selectors/categories';
import { locationByIdSelector } from 'Selectors/location';
import { getLocale } from 'Selectors/config';

import { DEFAULT_CATEGORY } from 'Constants/categories';
import { LISTING_TAP_REMOVE_FILTERS, FILTERSV2_EVENTS, SELECT_FROM } from 'Constants/tracking';

import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';

import { buildURL } from 'Helpers/url';
import { popFilter, removeFilter, removeOtherFilters } from 'Helpers/filters';
import { isEmpty, isUndefined } from 'Helpers/objects';
import { generateContent } from 'Helpers/filtersTanak';

import css from './TanakAppliedFilters.desktop.scss';
import { itemsSearchQuerySelector } from 'Selectors/items';
import isEqual from 'lodash/isEqual';

export class TanakAppliedFilters extends React.Component {
    static propTypes = {
        track: PropTypes.func.isRequired,
        categoryID: PropTypes.string,
        category: PropTypes.object.isRequired,
        geoLocation: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        similarAdsCount: PropTypes.number,
        isStaticFilters: PropTypes.bool,
        removedSearchterm: PropTypes.string,
        originalTotal: PropTypes.number,
        filterTags: PropTypes.arrayOf(PropTypes.object),
        appliedFiltersInfo: PropTypes.object,
        hiddenFilterIds: PropTypes.object,
        locale: PropTypes.string,
        addFiltersRecencyOrder: PropTypes.func.isRequired,
        clearFiltersPreferences: PropTypes.func.isRequired,
        clearFilterRecencyOrder: PropTypes.func.isRequired,
        currentSorting: PropTypes.string,
        searchQuery: PropTypes.string
    };

    static defaultProps = {
        category: {},
        geoLocation: {},
        location: {},
        params: {},
        filterTags: []
    }

    constructor(props) {
        super(props);

        const { config, appliedFiltersInfo } = props;

        this.seoObj = {
            useFakeLevelSlug: config.get('SEO', 'useFakeLevelSlug'),
            categoryCover: config.get('categoryCover')
        };

        props.clearFilterRecencyOrder();
        if (!isEmpty(appliedFiltersInfo)) {
            props.addFiltersRecencyOrder(Object.keys(appliedFiltersInfo));
        }
        this.showModernDesign = props.config.get('showModernDesign');
    }

    componentDidUpdate(prevProps) {
        const { categoryID, appliedFiltersInfo, addFiltersRecencyOrder } = this.props;

        if ((categoryID !== prevProps.categoryID && !isEmpty(appliedFiltersInfo))
        || (!isEmpty(appliedFiltersInfo) && !isEqual(appliedFiltersInfo, prevProps.appliedFiltersInfo))) {
            addFiltersRecencyOrder(Object.keys(appliedFiltersInfo));
        }
    }

    buildURL = (filterObj, removeAll, hiddenFilterIds) => {
        const {
            category,
            geoLocation: location,
            location: { query },
            searchQuery,
            appliedFiltersInfo
        } = this.props;
        // eslint-disable-next-line no-unused-vars
        const { filter, ...rest } = query;
        const { filterId, value } = filterObj || {};

        let newQuery;

        if (removeAll && !isEmpty(hiddenFilterIds)) {
            newQuery = {
                ...rest,
                filter: removeOtherFilters(appliedFiltersInfo, hiddenFilterIds)
            };
        }
        else if (removeAll) {
            newQuery = rest;
        }
        else {
            newQuery = {
                ...rest,
                filter: (value)
                    ? popFilter(appliedFiltersInfo, filterId, value)
                    : removeFilter(appliedFiltersInfo, filterId)
            };
        }

        return buildURL({
            category,
            location,
            params: newQuery,
            search: searchQuery
        }, this.seoObj);
    }

    fireTrackingEvent = (eventName, extraProps = {}) => {
        const { categoryID: category_id } = this.props;

        this.props.track(eventName, {
            category_id,
            select_from: SELECT_FROM.LISTING_PAGE,
            ...extraProps
        });
    }

    getFilterLabels = ({ label, props: filterProps, filterId }) => {
        const {
            locale,
            config,
            currentSorting,
            appliedFiltersInfo,
            isStaticFilters,
            similarAdsCount,
            hiddenFilterIds
        } = this.props;

        if (hiddenFilterIds?.[filterId]) {
            return null;
        }
        const to = this.buildURL({
            filterId,
            value: filterProps.id
        });
        const handleClick = () => {
            let { id: value, min, max } = filterProps;

            if (isUndefined(value)) {
                value = { min, max };
            }

            const newFilters = popFilter(appliedFiltersInfo, filterId, value);

            const visualizationType = config.get('visualizationType', 'desktop');

            this.fireTrackingEvent(LISTING_TAP_REMOVE_FILTERS, {
                filters: newFilters,
                filter_count: Object.keys(newFilters).length,
                chosen_option: filterId,
                filters_interaction: value,
                visual_applied: visualizationType,
                sorting_applied: currentSorting
            });
        };
        const modernLabel = { [css.modernLabel]: this.showModernDesign };

        if (similarAdsCount && (filterProps.isRelaxed || filterId === 'removedTerm')) {
            return (
                <span
                    key={ `label-${filterId}-${filterProps.id || filterProps.min || filterProps.max}` }
                    className={ css.staticFilter }
                >  { generateContent({ label, locale, props: filterProps }) }
                </span>
            );
        }

        else if (similarAdsCount || !isStaticFilters) {
            return (
                <Link
                    key={ `label-${filterId}-${filterProps.id || filterProps.min || filterProps.max}` }
                    to={ to }
                    className={ css.link }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ handleClick }
                >
                    <Label
                        labelClass={ classNames(css.label, modernLabel) }
                        content={ generateContent({ label, locale, props: filterProps }) }
                    />
                </Link>
            );
        }
        return (
            <span
                key={ `label-${filterId}-${filterProps.id || filterProps.min || filterProps.max}` }
                className={ classNames(css.staticFilter, css.blueStaticFilter) }
            >  { generateContent({ label, locale, props: filterProps }) }
            </span>
        );
    }

    handleClearAllFilters = () => {
        const {
            clearFilterRecencyOrder,
            clearFiltersPreferences
        } = this.props;

        this.fireTrackingEvent(FILTERSV2_EVENTS.CLEAR_ALL_FILTER, LayoutHelper.getAcquisitionChannelTrackingValues());
        clearFilterRecencyOrder();
        clearFiltersPreferences();
    }

    render() {
        const {
            filterTags,
            appliedFiltersInfo,
            originalTotal,
            similarAdsCount,
            removedSearchterm,
            isStaticFilters,
            hiddenFilterIds
        } = this.props;

        if (removedSearchterm) {
            const removedSearchFilter = { label: removedSearchterm, props: { filterProps: { isRelaxed: true }}, filterId: 'removedTerm' };

            if (!filterTags?.find(tags => tags.label === removedSearchterm)) {
                filterTags.push(removedSearchFilter);
            }
        }
        const hiddenFiltersAppliedCount = Object.values(hiddenFilterIds).reduce((acc, curr) => acc + curr, 0);

        if (isEmpty(appliedFiltersInfo) || !(filterTags.length - hiddenFiltersAppliedCount)) {
            return null;
        }

        return (
            <div className={ classNames(css.appliedFilters, { [css.staticAppliedFilter]: isStaticFilters },
                { [css.relaxedAppliedFilterWrapper]: similarAdsCount }) } >
                <div className={ css.filerLabels } data-aut-id={ similarAdsCount ? 'relaxed_filter_list' : '' }>
                    {filterTags.map(this.getFilterLabels)}
                </div>
                {(!!originalTotal || !!similarAdsCount) && <div className={ css.clearAll }>
                    <Button
                        className={ css.noPadRight }
                        type="flat"
                        size="small"
                        onClick={ this.handleClearAllFilters }
                        href={ this.buildURL(null, true, hiddenFilterIds) }
                    >
                        <Translation id="clearAll" />
                    </Button>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state, { categoryID = DEFAULT_CATEGORY, params }) => {
    const { geoID } = params;

    return {
        category: categorySelector(state, categoryID),
        geoLocation: locationByIdSelector(state)(geoID),
        appliedFiltersInfo: getParsedAppliedFilters(state, categoryID),
        locale: getLocale(state),
        hiddenFilterIds: getHiddenFilterIds(state),
        filterTags: getAppliedFiltersLabels(state, categoryID),
        currentSorting: getCurrentSorting(state, categoryID),
        searchQuery: itemsSearchQuerySelector(state, params.text)
    };
};

const mapDispatchToProps = {
    addFiltersRecencyOrder: addFiltersRecencyOrderActionCreator,
    clearFiltersPreferences: clearFiltersPreferencesActionCreator,
    clearFilterRecencyOrder: clearFilterRecencyOrderActionCreator
};

export default compose(
    withConfig,
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(TanakAppliedFilters);
