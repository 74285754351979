import React, { PureComponent } from 'react';
import { compose } from 'redux';
import withRouter from 'HOCs/withRouter';
import PropTypes from 'prop-types';
import Pagination from './Pagination.APP_TARGET';
import css from './PaginationWrapper.scss';

export class PaginationWrapper extends PureComponent {
    static propTypes = {
        'data-aut-id': PropTypes.string,
        total: PropTypes.number,
        onPageChanged: PropTypes.func,
        currentPage: PropTypes.number,
        pageSize: PropTypes.number,
        location: PropTypes.object,
        maxItems: PropTypes.number
    };

    static defaultProps = {
        'data-aut-id': 'pagination',
        pageSize: 20,
        location: {}
    };

    render() {
        const { 'data-aut-id': dataAutId, total, onPageChanged, currentPage, pageSize, location, maxItems } = this.props;

        return (
            <div className={ css.paginationWrapper }>
                <Pagination
                    total={ total }
                    data-aut-id={ dataAutId }
                    onPageChanged={ onPageChanged }
                    currentPage={ currentPage }
                    pageSize={ pageSize }
                    location={ location }
                    maxItems={ maxItems }
                />
            </div>
        );
    }
}

export default compose(
    withRouter
)(PaginationWrapper);
