/* global window */

/**
 * @param {*} eventName: string
 * @param {*} eventProps: object
 */
const exponeaTrack = (eventName = '', eventProps = {}) => {
    if (typeof window !== 'undefined' && window.exponea && window.exponea.track) {
        window.exponea.track(eventName, eventProps);
    }
};

const exponeaIdentify = eventProps => {
    if (typeof window !== 'undefined' && window.exponea && window.exponea.identify && !!eventProps && Object.keys(eventProps).length) {
        window.exponea.identify(eventProps);
    }
};

export { exponeaTrack, exponeaIdentify };
