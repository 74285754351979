import React from 'react';
import PropTypes from 'prop-types';
import css from '../../Listing.APP_TARGET.scss';
import { FormattedMessage as Translation } from 'react-intl';
import { getLabelInfo } from 'Helpers/listing';
import TanakAppliedFilters from 'Components/Listing/components/TanakAppliedFilters.APP_TARGET';
import SortingContainer from 'Components/Listing/components/SortingContainer.APP_TARGET';
import { isMobile } from 'Helpers/devices';
import { CARS_CATEGORY_PAGE_SIZE } from 'Constants/items';
import SectionTitle from 'Components/Listing/components/Sections/SectionTitle';

export const ListingHeader = ({
    itemsMetadata,
    categoryID,
    isFetchingItems,
    similarAdsCount,
    isStaticFilters,
    isFetchingLocation,
    isSortingOpen,
    locationDetails,
    originalTotal,
    suggestedItemsTotal,
    trackListingSelectSorting,
    trackListingApplySorting,
    visualizationType,
    dynamicContent,
    currentPage,
    sectionOffset,
    sectionsItems
}) => {
    if (isFetchingItems || isFetchingLocation) {
        return <p className={ css.listingHeaderTitle }><Translation id="loading" /></p>;
    }

    const {
        original_label,
        show_original_items,
        show_suggested_items,
        suggested_label
    } = itemsMetadata;

    const { itemsLabel, total, labelType } = getLabelInfo({
        totalItems: originalTotal,
        totalSuggestedAds: suggestedItemsTotal,
        similarAdsCount,
        show_original_items,
        show_suggested_items,
        original_label,
        suggested_label
    });

    const itemIndex = 0;
    const newItemIndex = itemIndex + (currentPage - 1) * CARS_CATEGORY_PAGE_SIZE;
    let hasSession = sectionOffset && Boolean(sectionOffset.find((e, i, a) => newItemIndex > e && a[i + 1] > newItemIndex)
        || sectionOffset[sectionOffset.length - 1] < newItemIndex);

    // If only one Bucket title appear on page 1 and only on the top of the page,
    // then it should appear on page 2 onwards.
    if (sectionOffset[0] === 0 && currentPage > 1) {
        hasSession = true;
    }

    let section = null;

    if (newItemIndex === 0 || sectionsItems?.length === 0) {
        section = undefined;
    }
    if (sectionsItems?.length > 0) {
        if (newItemIndex >= sectionsItems[sectionsItems.length - 1]?.offset) {
            section = sectionsItems[sectionsItems.length - 1];
        }
        for (let i = 0; i < sectionsItems.length; i++) {
            if (newItemIndex < sectionsItems[i]?.offset) {
                section = sectionsItems[i - 1];
                break;
            }
        }
    }

    return (<div>
        {!!similarAdsCount && <p className={ css.similarAdsCountTitle }><Translation id={ 'matchSomeRequirements' } /><span className={ css.totalResults }> [{itemsMetadata.total_similar_ads} <Translation id={ 'results' } />]</span></p>}
        <TanakAppliedFilters
            categoryID={ categoryID }
            similarAdsCount={ similarAdsCount }
            isStaticFilters={ isStaticFilters }
            originalTotal={ originalTotal }
            removedSearchterm={ itemsMetadata.removed_term }
        />
        { isMobile ? dynamicContent : undefined }
        <SortingContainer
            itemsMetadata={ itemsMetadata }
            renderType="modal"
            total={ total }
            labelFlag={ show_original_items || show_suggested_items }
            labelType={ labelType }
            label={ itemsLabel }
            location={ locationDetails }
            isSortingOpen={ isSortingOpen }
            visualizationType={ visualizationType }
            trackListingSelectSorting={ trackListingSelectSorting }
            trackListingApplySorting={ trackListingApplySorting }
            categoryID={ categoryID }
        />
        { hasSession && section ? <SectionTitle section={ section } currentPage={ currentPage } /> : undefined }
    </div>);
};

ListingHeader.propTypes = {
    categoryID: PropTypes.string.isRequired,
    isFetchingItems: PropTypes.bool,
    isFetchingLocation: PropTypes.bool,
    isStaticFilters: PropTypes.bool,
    similarAdsCount: PropTypes.number,
    isSortingOpen: PropTypes.bool,
    locationDetails: PropTypes.object,
    originalTotal: PropTypes.number,
    suggestedItemsTotal: PropTypes.number,
    trackListingSelectSorting: PropTypes.func,
    trackListingApplySorting: PropTypes.func,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    itemsMetadata: PropTypes.object,
    dynamicContent: PropTypes.node,
    currentPage: PropTypes.number,
    sectionOffset: PropTypes.arrayOf(PropTypes.number),
    sectionsItems: PropTypes.arrayOf(PropTypes.object)
};

ListingHeader.defaultProps = {
    spellCheckInfo: {},
    isFetchingItems: false,
    isFetchingLocation: false,
    isSortingOpen: false,
    locationDetails: {},
    trackListingSelectSorting: () => {},
    trackListingApplySorting: () => {},
    visualizationType: 'grid',
    itemsMetadata: {},
    currentPage: 1,
    sectionOffset: [],
    sectionsItems: []
};

export default ListingHeader;
